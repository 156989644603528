import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import {
  IconLogo,
} from "../../components/Common/Icons";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const histoy = useHistory();
  const alert = useAlert()
  const submitEmail = (e) => {
    e.preventDefault();
    const query = {
      url: `${process.env.REACT_APP_AUTH_URL}/send-otp?email=${email}&isForgot=${true}`,
      headers: {
        "Content-Type": "application/json"
      },
      method: "get",
    }
    axios(query).then(() => {
      alert.success("OTP sent Successfully")
      histoy.push("/verify-otp", { isForgot: true , email: email })
    }).catch((err) => {
      alert.error("User doesn't exsist.")
    })
  }

  return (
    <div className="signup-wrapper bg-animation h-100 bg-gray-50">
      <div className="row no-gutters d-flex min-vh-100 align-items-center">
        <div className="col-lg-3 col-xl-3"></div>
        <div className="col-lg-6 col-xl-6">
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            <div className="w-100 d-flex justify-content-center align-items-end">
              <div className="">
                <IconLogo />
                <div className="bg-dark p-10 text-center text-white font-12 text-uppercase letter-spacing-5">
                  Developers
                </div>
              </div>
            </div>
            <div className="form-wrapper w-max p-50 bg-white rounded-10">
              <form className="form-sm" onSubmit={submitEmail} style={{ width: "300px" }}>
                <div className="form-group mb-20" style={{ marginTop: "70px" }}>
                  <label className="mb-10">
                    <span className="text-danger">*</span> Email Address
                  </label>
                  <input
                    className="form-control p-10"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mt-40 d-flex flex-column align-items-center">
                  <button
                    className="btn btn-lg btn-primary w-100 justify-content-center"
                    type="submit"
                  >
                    Send
                  </button>
                  <button className="m-3" style={{ border: 'none', backgroundColor: "transparent", color: "hsl(218, 91%, 59%)"}} onClick={() => histoy.push("/signin")}> Go back </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-xl-3"></div>
      </div>
    </div>
  );
};
export default ForgotPassword;
