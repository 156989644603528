import React from 'react'
import DynamicComponent from '../../../components/Common/DynamicComponent/DynamicComponent'

const data = {
    title: "Create Request to Company",
    subTitle: "This endpoint will make a request to the carrier.",
    requestLink: "GET  /customer-request",
    title2: "API Setup",
    requestLink2: "Authorization: <Your Access Token>",
    note: "You have to reach the company(carrier) for approval for the request for production environment and for Sandbox approval process is not needed.",
    queryParameters: [
      {
        parameter: "carrierID",
        type: "String",
        description: "Carrier unique ID string",
        isRequired: true
      },
      {
        parameter: "environment",
        type: "String[“sandbox”,“production”]",
        description: "Environment which you want. either sandbox or production.",
        isRequired: true
      },
      {
        parameter: "description",
        type: "String",
        description: "Description of request."
      }
    ],
    customName: "Request body"
  };

  const jsonContent = `{
    "_object": "/api/response",
    "self": "/v1/customer-request",
    "version": "1.0.0",
    "data": {
        "status": "PENDING",
        "isDeleted": false,
        "_id": "4567",
        "carrierID": "F0ED87QQ18",
        "description": "test description",
        "masterCustomerId": "1234",
        "environment": "production",
        "createdAt": "2022-07-22T09:12:22.223Z",
        "updatedAt": "2022-07-22T09:12:22.223Z",
        "__v": 0
    },
    "error": null
 }`;
  

export const RequestToCompany = () => {
  return (
    <div><DynamicComponent  props={data} content={jsonContent}/> </div>
  )
}
