import axios from 'axios';

function objToQueryParams(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(
                encodeURIComponent(p) +
                    '=' +
                    encodeURIComponent(typeof obj[p] === 'string' ? obj[p] : JSON.stringify(obj[p]))
            );
        }
    return str.join('&');
}

export async function getOccurredWebhooks(token, params) {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',

                Authorization: `${token}`,
            },
        };
        const query = {
            url: `${process.env.REACT_APP_URL}/webhook/occured-customer-webhooks`,
            method: 'get',
            headers: config.headers,
        };
        query.url = params ? query.url + '?' + objToQueryParams(params) : query.url;
        const { data } = await axios(query);
        return data.data;
    } catch (error) {
        return error;
    }
}

export async function getAllCarrierForMasterCustomer(token) {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
            },
        };
        const query = {
            url: `${process.env.REACT_APP_URL}/webhook/get-all-carriers`,
            method: 'get',
            headers: config.headers,
        };
        const { data } = await axios(query);
        return data;
    } catch (err) {
        return err;
    }
}

export const events = [
    { label: 'load#dates_updated', value: 'load#dates_updated' },
    { label: 'tendor#status_changed', value: 'tendor#status_changed' },
    { label: 'load#document_added', value: 'load#document_added' },
    { label: 'load#status_updated', value: 'load#status_updated' },
    { label: 'load#return_container', value: 'load#return_container' },
    { label: 'load#deliver_load', value: 'load#deliver_load' },
    { label: 'load#pull_container', value: 'load#pull_container' },
    { label: 'load#chassis_pick', value: 'load#chassis_pick' },
    { label: 'load#chassis_termination', value: 'load#chassis_termination' },
    { label: 'load#drop_container', value: 'load#drop_container' },
    { label: 'load#hook_container', value: 'load#hook_container' },
    { label: 'appointments#port_pickup', value: 'appointments#port_pickup' },
    { label: 'appointments#consignee', value: 'appointments#consignee' },
    { label: 'appointments#port_return', value: 'appointments#port_return' },
    { label: 'load#invoice_created', value: 'load#invoice_created' },
];

export const getLastWeeksDate = ()=> {
    const now = new Date();
  
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
}