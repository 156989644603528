import React, { useEffect, useState, useRef } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { getWebhookHistory } from '../../actions/apiKeyActions';
import { clearErrors } from '../../actions/userActions';
import DataGrid from 'react-data-grid';
import { CodingBlock } from '../../components/Common/DynamicComponent/CodingBlock';
import { IconEye } from '../../components/Common/Icons';
import { getOccurredWebhooks, events, getAllCarrierForMasterCustomer, getLastWeeksDate } from './historyHelper';
import Loader from '../../components/Common/Loader/Loader';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { smallSelectStyle } from '../../assets/js/select-style';
import moment from 'moment';


const History = ({ token }) => {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [limit, setLimit] = useState(20);
    const [skip, setSkip] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [remountKey, setRemountKey] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [singleRow, setSingleRow] = useState({});
    const [eventFilter, setEventFilter] = useState([]);
    const [nameOptions, setNameOptions] = useState([]);
    const [startDate,setStartDate] = useState(getLastWeeksDate().toUTCString());
    const [endDate,setEndDate] = useState(new Date().toUTCString());
    const [nameFilter, setNameFilter] = useState([]);
    const [filterParamsUpdated,setFilterParamsUpdated] = useState(false);
    const [count, setCount] = useState(0);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    
    const handleFilter = () =>{
        getColumns();
        populateRows(false);
    }
    
    useEffect(()=>{
        handleFilter()
    },[])

    const getColumns = () => {
        let columns = [
            {
                key: 'show',
                name: '',
                width: 50,
                cellClass: 'cell d-flex justify-content-center',
                formatter: ({ row }) => {
                    return (
                        <button
                            className="btn btn-link"
                            onClick={() => {
                                setSingleRow(row);
                                setShowModal(true);
                            }}
                        >
                            <IconEye className="text-muted" />
                        </button>
                    );
                },
            },
            {
                key: 'event',
                name: 'Event',
                width: 250,
                cellClass: 'cell d-flex justify-content-center',
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="cell-content font-12">{row?.eventType}</div>;
                },
            },
            // {
            //   key: "carrier",
            //   name: "Carrier",
            //   width: 200,
            //   cellClass: "cell d-flex justify-content-center",
            //   resizable: true,
            //   formatter: ({ row }) => {
            //     return <div class="cell-content font-12">{row?.carrier?.name +" "+ (row?.carrier?.lastName ? row?.carrier?.lastName : "")}</div>
            //   },
            // },
            {
                key: 'environment',
                name: 'Environment',
                width: 150,
                cellClass: 'cell d-flex justify-content-center',
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="cell-content font-12">{row?.environment}</div>;
                },
            },
            {
                key: 'webhookendpoint',
                name: 'Webhook Endpoint',
                width: 300,
                cellClass: 'cell d-flex justify-content-center',
                resizable: true,
                formatter: ({ row }) => {
                    return (
                        <>
                            <div class="cell-content font-12 ml-2">{row?.webhookUrl}</div>
                        </>
                    );
                },
            },
            {
                key: 'code',
                name: 'Webhook Data',
                width: 300,
                cellClass: 'cell d-flex justify-content-center',
                resizable: true,
                formatter: ({ row }) => {
                    return (
                        <>
                            <div class="cell-content font-12 ml-2">{JSON.stringify(row?.data)}</div>
                        </>
                    );
                },
            },
            {
                key: 'error',
                name: 'Error',
                width: 200,
                cellClass: 'cell d-flex justify-content-center',
                resizable: true,
                formatter: ({ row }) => {
                    return (
                        <>
                            <div class="cell-content font-12 ml-2">{row?.error ? row?.error : '-'}</div>
                        </>
                    );
                },
            },
            {
                key: 'time',
                name: 'Time(UTC)',
                width: '100vw',
                cellClass: 'cell d-flex justify-content-center',
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="cell-content font-12">{new Date(row?.createdAt).toUTCString()}</div>;
                },
            },
        ];

        setColumns(columns);
    };

    const isAtBottom = ({ currentTarget }) => {
        return !(currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight);
    };

    const EmptyRowsRenderer = () => {
        return (
            <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
                <div style={{ padding: '20px 10px', backgroundColor: 'white', color: 'black', fontSize: '12px' }}>
                    No Record Found
                </div>
            </div>
        );
    };

    const populateRows = (append) => {
        setLoading(true);
        let eventType = eventFilter.map((event) => event.value);
        let carriers = nameFilter.map((name) => name.value);
        let query = {
            skip,
            limit,
        };
        if (eventType?.length > 0) query.eventType = eventType;
        if (carriers?.length > 0) query.carriers = carriers;
        if (startDate) query.startDate = new Date(startDate)?.toISOString();
        if (endDate) query.endDate = new Date(endDate)?.toISOString();
        getOccurredWebhooks(token, query).then((response) => {
            if (append) {
                if (rows) setRows((rows) => [...rows, ...response.data]);
                setCount(response.count);
            } else {
                if (response.data?.length > 0) {
                    setRows(response.data);
                    setCount(response.count);
                } else {
                    setRows([]);
                    setCount(0);
                }
                setRemountKey((remountKey) => remountKey + 1);
            }

            setLoading(false);
            setFilterParamsUpdated(false)
        }).catch((err)=>{
            console.log("Error : ",err)
            setLoading(false);
        });
    };

    const handleScroll = (e) => {
        if (isAtBottom(e) || loading || skip + limit >= count) return;
        setSkip(() => skip + limit);
        populateRows(true);
    };

    const prepareCodeData = (data) => {
        return JSON.stringify(data,null,4)
    };

    return (
        <div className="card p-20 mt-15 border-0">
            {loading ? <Loader /> : null}
            <h3 className="font-16 mb-20 mt-15 font-bold">Occurred Webhook</h3>
            <div className="row m-1">
                <div className="ml-auto d-flex flex-shrink-0 ml-2 mb-4 align-items-center p-2 rounded-2" style={{backgroundColor:"#f1f4f8"}}>
                    <div className="w-auto">
                        <Select
                            isMulti
                            styles={smallSelectStyle}
                            placeholder="Select Event Type"
                            options={events}
                            onChange={(event) => {
                                setSkip(0);
                                setEventFilter(() => event);
                                setFilterParamsUpdated(true)
                            }}
                        />
                    </div>
                    <div className='ml-1 d-flex'>
                        <div className="d-flex justify-content-center align-items-center">
                            <input 
                            max={moment(endDate).format("YYYY-MM-DDTHH:mm").toString()} 
                            type="datetime-local"
                            ref={startDateRef}
                            className='p-2 mx-1 border-1 border-secondary rounded-1 border-primary'
                            value={moment(startDate).format("YYYY-MM-DDTHH:mm").toString()}
                            onClick={()=>startDateRef.current.showPicker()}
                            onChange={(e)=>{
                                setSkip(0)
                                if(moment(e.target.value).isValid())
                                {
                                    setStartDate(moment.utc(e.target.value))
                                    setFilterParamsUpdated(true)
                                }
                            }}/>
                            {"-"}
                            <input
                            className='p-2 mx-1 border-1 border-secondary rounded-1 border-primary'
                            min={moment(startDate).format("YYYY-MM-DDTHH:mm").toString()} 
                            type="datetime-local"
                            ref={endDateRef}
                            value={moment(endDate).format("YYYY-MM-DDTHH:mm").toString()} 
                            onClick={()=>endDateRef.current.showPicker()}
                            onChange={(e)=>{
                                setSkip(0)
                                if(moment(e.target.value).isValid())
                                {
                                    setEndDate(moment.utc(e.target.value))
                                    setFilterParamsUpdated(true)
                                }
                            }}/>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                        </div>
                    </div>
                    <div className='ml-1 d-flex'>
                        <button className="btn btn-primary" onClick={()=>handleFilter()}
                        disabled={!filterParamsUpdated}
                        >
                            Apply
                        </button>
                    </div>
                    {/* <div className='w-200'>
                        <Select
                        isMulti
                        styles={smallSelectStyle}
                        className={"ml-2"}
                        placeholder="Select Carrier Name"
                        options={nameOptions}
                        onChange={(event) => {
                        setSkip(0)
                        setNameFilter(() => event)
                        }}
                        />
                        </div> */}
                </div>
            </div>

            <DataGrid
                key={remountKey}
                columns={columns}
                rows={rows || []}
                sortColumns={[]}
                rowHeight={50}
                defaultColumnOptions={{
                    sortable: true,
                }}
                headerRowHeight={30}
                className={'fill-grid font-12 rdg-light customer-grid'}
                onScroll={handleScroll}
                components={{
                    noRowsFallback: <EmptyRowsRenderer />,
                }}
                style={{ height: 'calc(100vh - 410px)' }}
            />
                <Modal centered show={showModal} animation={true} bsSize="xl" onHide={()=>setShowModal(false)}>
                    <Modal.Header>
                        <h4 className="modal-title" id="exampleModalLongTitle">
                            {singleRow?.eventType}
                        </h4>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <h5 className="text-muted font-14 mb-1">Time(UTC)</h5>
                                    <span className="text-gray-900 font-12">
                                        {new Date(singleRow?.createdAt).toUTCString()}
                                    </span>
                                </div>
                                {/* <div className="col-md-4">
                                    <h5 className="text-muted font-14 mb-1">Carrier</h5>
                                    <span className="text-gray-900 font-12">
                                        {singleRow?.carrier?.name +
                                            ' ' +
                                            (singleRow?.carrier?.lastName ? singleRow?.carrier?.lastName : '')}
                                    </span>
                                </div> */}
                                <div className="col-md-4">
                                    <h5 className="text-muted font-14 mb-1">Environment</h5>
                                    <span className="text-gray-900 font-12">{singleRow?.environment}</span>
                                </div>
                            </div>
                            <div className="form-group row my-3">
                                <div className="col-md-12">
                                    <h5 className="text-muted font-14 mb-1">Webhook Endpoint</h5>
                                    <span className="text-primary font-12">{singleRow?.webhookUrl}</span>
                                </div>
                            </div>
                            {singleRow?.error && (
                                <div className="form-group row my-4">
                                    <div className="col-md-12">
                                        <h5 className="text-danger font-14 mb-1">Webhook Error</h5>
                                        <div className="overflow-y-auto" style={{ maxHeight: '225px' }}>
                                            <CodingBlock content={prepareCodeData(singleRow?.error)} />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {singleRow?.data && (
                                <div className="form-group row mt-4">
                                    <div className="col-md-12">
                                        <h5 className="text-muted font-14 mb-1">Webhook Data</h5>
                                        <div className="overflow-y-auto" style={{ maxHeight: '225px' }}>
                                            <CodingBlock content={prepareCodeData(singleRow?.data)} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button
                                type="button"
                                className="btn border-0 text-muted font-weight-500"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
        </div>
    );
};

export default History;
