import React from "react";
import { CodingBlock } from "./CodingBlock";
import NoteComponent from "./components/NoteComponent";
import { Image } from "react-bootstrap";

const DynamicComponent = ({ props, content, noCodebase, isHeader }) => {
  const {
    title,
    title2,
    subTitle,
    description,
    description1,
    description2,
    description3,
    requestLink,
    requestLink2,
    queryParameters,
    paramsParameters,
    requestParameters,
    customName,
    note,
    remember,
    requestHeader,
    orderList,
    errorCodes,
    image,
    description4,
    types,
    typeTitle,
    statusData,
    statusHeading,
    statusDetails,
  } = props;
  return (
    <div className="page-fluid">
      <div className="row no-gutters">
        <div className={content ? "col-lg-7" : "col-lg-12"}>
          <div className="m-3 bg-white">
            <div className="border-top border-bottom p-15">
              {title && <h2 className="font-bold font-22 m-0">{title}</h2>}
            </div>
            <div className="p-15">
              {subTitle && (
                <h6 className="font-medium font-16 m-0 pb-20">{subTitle}</h6>
              )}
              {description && (
                <p className="font-regular font-14 text-gray-600">
                  {description}
                </p>
              )}
              {types && (
                <div className="mb-2">
                  <table>
                    <thead>
                      <tr>
                        <th>{typeTitle ? typeTitle : "Type of Document"}</th>
                      </tr>
                    </thead>
                    {types.map((item) => (
                      <tr>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}

              {orderList && (
                <ol className="m-3 font-regular font-14 text-gray-600">
                  {orderList.map((item, index) => (
                    <li
                      className="font-regular font-14 text-gray-600"
                      key={index}
                    >
                      {item}
                    </li>
                  ))}
                </ol>
              )}

              {description1 && (
                <p className="font-regular font-14 text-black-600">
                  {description1}
                </p>
              )}

              {description2 && (
                <p className="font-regular font-14 text-black-600">
                  {description2}
                </p>
              )}

              {description3 && (
                <p className="font-regular font-14 text-black-600">
                  {description3}
                </p>
              )}
              {description4 && (
                <p className="font-regular font-14 text-black-600">
                  {description4}
                </p>
              )}

              {requestLink && (
                <>
                  <h6 className="font-bold font-16 mt-30">HTTP Request</h6>
                  <p className="font-regular font-12 letter-spacing-1 text-gray-bold bg-light p-1 d-inline-block">
                    {requestLink}
                  </p>
                </>
              )}
              {title2 && <h6 className="font-bold font-16 mt-30">{title2}</h6>}
              {image && <Image src={image} alt="Authentication Chart" />}
              {requestLink2 && (
                <p className="font-regular font-12 letter-spacing-1 text-gray-bold bg-light p-1 d-inline-block">
                  {requestLink2}
                </p>
              )}
            </div>
            <div className="p-15">
              {requestHeader && (
                <>
                  <h6 className="font-medium font-16">Request Header</h6>
                  <p className="font-regular font-14 text-white bg-primary p-10">
                    {requestHeader}
                  </p>
                </>
              )}

              {paramsParameters && (
                <div className="mb-5">
                  <h6 className="font-bold font-16">
                    {isHeader ? "Request Headers" : "Params Parameters"}
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <th>Parameter</th>
                        <th>Type</th>
                        <th>Description</th>
                      </tr>
                      {paramsParameters.map((item, index) => (
                        <tr key={index}>
                          <td>{item.parameter}</td>
                          <td>{item.type}</td>
                          <td>{item.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {queryParameters && (
                <div className="my-2">
                  <h6 className="font-bold font-16">
                    {customName ? customName : "Query Parameters"}
                  </h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Parameter</th>
                        <th>Required</th>
                        <th>Type</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    {queryParameters.map((item) => (
                      <tr>
                        <td>{item.parameter}</td>
                        <td>{item.isRequired ? "true" : "false"}</td>
                        <td>
                          <em>{item.type}</em>
                        </td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
              {statusDetails && (
                <>
                  <section className="create-tender-qs border-bottom my-3">
                    <h4>Load Status Details</h4>
                    <div className="my-3">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        {statusDetails.map((item) => (
                          <tr>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </section>
                </>
              )}
              {statusData && (
                <>
                  <section className="create-tender-qs border-top border-bottom p-15 mt-3">
                    <h4>Load Status Details</h4>
                    <p className="font-regular font-14 text-gray-600">
                      You will receive load status change event for below
                      statuses.
                    </p>
                    <div className="my-2">
                      <h6 className="font-bold font-16">
                        {customName ? customName : "Query Parameters"}
                      </h6>
                      {statusData?.statusHeading1 && (
                        <h6 className="font-bold font-16 my-2">
                          {statusData?.statusHeading1}
                        </h6>
                      )}
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        {statusData?.statusDetails.map((item) => (
                          <tr>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                          </tr>
                        ))}
                      </table>
                      {statusData?.statusHeading2 && (
                        <h6 className="font-bold font-16 my-3">
                          {statusData?.statusHeading2}
                        </h6>
                      )}
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        {statusData?.statusDetails2.map((item) => (
                          <tr>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                          </tr>
                        ))}
                      </table>
                      {statusData?.statusHeading3 && (
                        <h6 className="font-bold font-16 my-3">
                          {statusData?.statusHeading3}
                        </h6>
                      )}
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        {statusData?.statusDetails3.map((item) => (
                          <tr>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </section>
                </>
              )}
            </div>
            {errorCodes && (
              <div className="p-15">
                {errorCodes && (
                  <table>
                    <thead>
                      <tr>
                        <th>Error Code</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {errorCodes.map((item, index) => (
                        <tr key={index}>
                          <td>{item.code}</td>
                          <td>{item.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
            <div className="p-15">
              {requestParameters && (
                <div className="pt-10">
                  <h6 className="font-bold font-16">Request Body</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Parameter</th>
                        <th>Required</th>
                        <th>Type</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    {requestParameters.map((item) => (
                      <tr>
                        <td>{item.parameter}</td>
                        <td>{item.isRequired ? "true" : "false"}</td>
                        <td>
                          <em>{item.type}</em>
                        </td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
              <NoteComponent note={note} />
              {remember && (
                <div className="bg-success px-20 py-15 mt-20 text-white">
                  <span className="font-12 text-capitalize">
                    <i className="uil-check-circle font-16 mr-1"></i> {remember}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {content && (
          <div className="col-lg-5" style={{ backgroundColor: "#1d1f21" }}>
            <div>{!noCodebase && <CodingBlock content={content} />}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicComponent;
