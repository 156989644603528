import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import {
  IconLogo,
  IconEyeHidden,
  IconEye
} from "../../components/Common/Icons";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const histoy = useHistory();
  const alert = useAlert();
  const location = useLocation();
  useEffect(() => {
    if(!location?.state?.email) histoy.push("/signin");
  }, [])

  const submitPassword = (e) => {
    e.preventDefault();
    const query = {
      url: `${process.env.REACT_APP_AUTH_URL}/set-password?email=${location?.state?.email}&otp=${location?.state?.otp}&password=${password}`,
      headers: {
        "Content-Type": "application/json",
      },
      method: "get",
    };
    axios(query)
      .then(() => {
        alert.success("Password Changed Successfully");
        histoy.push("/signin");
      })
      .catch((err) => {
        alert.error("Something went wrong");
      });
  };
  return (
    <div className="signup-wrapper bg-animation h-100 bg-gray-50">
      <div className="row no-gutters d-flex min-vh-100 align-items-center">
        <div className="col-lg-3 col-xl-3"></div>
        <div className="col-lg-6 col-xl-6">
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            <div className="w-100 d-flex justify-content-center align-items-end">
              <div className="">
                <IconLogo />
                <div className="bg-dark p-10 text-center text-white font-12 text-uppercase letter-spacing-5">
                  Developers
                </div>
              </div>
            </div>
            <div className="form-wrapper w-max p-50 bg-white rounded-10">
              <form
                className="form-sm"
                onSubmit={submitPassword}
                style={{ width: "300px" }}
              >
                <div className="form-group mb-20" style={{ marginTop: "70px" }}>
                  <label className="mb-10">
                    <span className="text-danger">*</span> Password
                  </label>
                  <div className="input-wrapper">
                    <input
                      type={show ? "text" : "password"}
                      className="form-control p-10"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <span className="input-icon" onClick={() => setShow(!show)}>
                      {show ? <IconEyeHidden /> : <IconEye />}
                    </span>
                  </div>
                </div>
                <div className="form-group mt-40">
                  <button
                    className="btn btn-lg btn-primary w-100 justify-content-center"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-xl-3"></div>
      </div>
    </div>
  );
};

export default ResetPassword;
