import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";
import { statusData } from "../../../utils/status.utils"

const data = {
  title: "Events",
  description:
    "This page describes the different event types which trigger an event object to be sent to your registered Webhook Endpoints.",
  requestLink2: " All the event webhooks has POST Requests.",
};
const data1 = {
  title: "Load Routing Event",
  customName: " ",
  queryParameters: [
    {
      parameter: "Pull container",
      description:
        "It will trigger when some changes will happen in pull container.",
      type: "load#pull_container",
    },
    {
      parameter: "Drop container",
      description:
        "It will trigger when some changes will happen in drop container.",
      type: "load#drop_container",
    },
    {
      parameter: "Hook container",
      description:
        "It will trigger when some changes will happen in hook container.",
      type: "load#hook_container",
    },
    {
      parameter: "Deliver load",
      description:
        "It will trigger when some changes will happen in deliver load.",
      type: "load#deliver_load",
    },
    {
      parameter: "Return container",
      description:
        "It will trigger when some changes will happen in return container.",
      type: "load#return_container",
    },
    {
      parameter: "Chassis pick",
      description:
        "It will trigger when some changes will happen in chassis pick.",
      type: "load#chassis_pick",
    },
    {
      parameter: "Chassis termination",
      description:
        "It will trigger when some changes will happen in chassis termination.",
      type: "load#chassis_termination",
    },
  ],
};

const data2 = {
  title: "Appointment Event",
  customName: " ",
  queryParameters: [
    {
      parameter: "Port pickup",
      description:
        "It will trigger when some changes will happen in port pickup appointment.",
      type: "appointments#port_pickup",
    },
    {
      parameter: "Consignee",
      description:
        "It will trigger when some changes will happen in consignee appointment.",
      type: "appointments#consignee",
    },
    {
      parameter: "Port return",
      description:
        "It will trigger when some changes will happen in port return appointment.",
      type: "appointments#port_return",
    },
  ],
};
const data3 = {
  title: "Invoice Event",
  customName: " ",
  queryParameters: [
    {
      parameter: "Invoice created",
      description: "It will trigger when invoice create for your load.",
      type: "load#invoice_created",
    },
  ],
};

const data4 = {
  title: "Load / Tender Event",
  customName: " ",
  queryParameters: [
    {
      parameter: "Tender Status Change",
      description: "It will trigger when your tendor is accepted or rejected.",
      type: "tendor#status_changed",
    },
    {
      parameter: "Load Document",
      description: "It will trigger when load documents was added.",
      type: "load#document_added",
    },
    {
      parameter: "Load Status Change",
      description: "It will trigger when load status was changed.",
      type: "load#status_updated",
    },
    {
      parameter: "Load Notes Update",
      description: "It will trigger when any notes are added or updated.",
      type: "load#notes",
    }
  ],
  statusData
};

const jsonContent = `{
    routing_event: [
      { label: "Pull container", value: "load#pull_container" },
      { label: "Drop container", value: "load#drop_container" },
      { label: "Hook container", value: "load#hook_container" },
      { label: "Deliver load", value: "load#deliver_load" },
      { label: "Return container", value: "load#return_container" },
      { label: "Chassis pick", value: "load#chassis_pick" },
      { label: "Chassis termination", value: "load#chassis_termination" },
    ]
  }`;
const jsonContent1 = `{
    appointment_event: [
      { label: "Port pickup", value: "appointments#port_pickup" },
      { label: "Consignee", value: "appointments#consignee" },
      { label: "Port return", value: "appointments#port_return" },
    ]
  }`;
const jsonContent2 = `{
    invoice_event: [
      { label: "Invoice created", value: "load#invoice_created" },
    ]
  }`;

const jsonContent3 = `{
    load_tendor_event: [
      { label: "Tender Status Change", value: "tendor#status_changed" },
      { label: "Load Document Added", value: "load#document_added" },
      { label: "Load Status Change", value: "load#status_updated" },
      { label: "Load Notes Update", value: "load#notes" }
    ]
  }`;

export const Events = () => {
  return (
    <div>
      <DynamicComponent props={data} content=" " />
      <DynamicComponent
        onlyContent={true}
        props={data4}
        content={jsonContent3}
      />
      <DynamicComponent
        onlyContent={true}
        props={data1}
        content={jsonContent}
      />
      <DynamicComponent
        onlyContent={true}
        props={data2}
        content={jsonContent1}
      />
      <DynamicComponent
        onlyContent={true}
        props={data3}
        content={jsonContent2}
      />
    </div>
  );
};
