import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const Webhooks = () => {
  const data = {
    title: "Webhook Endpoints",
    description:
      "Webhook endpoints can be created in your PortPro Developer Section. They allow you to be notified of changes on your PortPro data and events for your shipments.",
  };

  const data1 = {
    title: "Setting a Secret Token",
    description:
      "In order to verify that only requests coming from PortPro are allowed into your system, we require you to set a secret token for each of your endpoints. PortPro sends an X-Hub-Signature header with the value set as demonstrated on the right with each message. By verifying this header matches your expectations, we can ensure that only people with the secret token can send data to your endpoint. ",
      requestLink2:`X-Hub-Signature: sha1=<Your TOKEN KEY>`,
      note: " You must replace Your TOKEN KEY with your personal Access Token.",
  };

  const data2 = {
    title: "Payloads",
    description:
      "The payload for a webhook is an Event object. PortPro uses secret hash signature. This hash signature is passed along with each request in the headers as X-Hub-Signature. To validate the payload.",
    orderList: [
      "Generate a signature using the PortPro Developer Portal.",
      "Compare your signature to the signature in the X-Hub-Signature header string. If they match, then the payload is genuine.",
    ],
  };
  const data3 = {
    title: "Response",
    description:"PortPro expects to receive an HTTP 200 response code from your endpoint. We will continue trying to deliver the message a number of times if we don’t receive one. To prevent time outs, it is wise to configure your endpoints to send a 200 and then do any long running processing on the data.",
  };
  const jsonContent = "";

  return (
    <div>
      <DynamicComponent onlyContent={true} props={data} content={jsonContent} />
      <DynamicComponent
        noCodebase={true}
        onlyContent={true}
        props={data1}
        content={jsonContent}
      />
      <DynamicComponent
        noCodebase={true}
        onlyContent={true}
        props={data2}
        content={jsonContent}
      />
      <DynamicComponent
        noCodebase={true}
        onlyContent={true}
        props={data3}
        content={jsonContent}
      />
    </div>
  );
};

export default Webhooks;
