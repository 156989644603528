exports.statusData = {
  statusHeading1: "Import Load Status",
  statusDetails: [
    {
      name: "Pending",
      description: "The Load is Pending.",
    },
    {
      name: "Available",
      description: "The Load is Available.",
    },
    {
      name: "Dispatched",
      description: "The Load is Dispatched.",
    },
    {
      name: "Enroute to Chassis",
      description: "Enroute to pick up the Chassis.",
    },
    {
      name: "Arrived to Chassis",
      description: "Arrived at pick up the Chassis.",
    },
    {
      name: "Enroute to Pick Container",
      description: "Enroute to Pick up the Container.",
    },
    {
      name: "Arrived at Pick Container",
      description: "Arrived at pick up the Container.",
    },
    {
      name: "Enroute to Drop Container",
      description: "Enroute to Drop the Container.",
    },
    {
      name: "Dropped",
      description: "The container is Dropped.",
    },
    {
      name: "Dropped-Empty",
      description: "Dropped container is empty.",
    },
    {
      name: "Dropped-Loaded",
      description: "Dropped container is loaded.",
    },
    {
      name: "Enroute to Hook Container",
      description: "Enroute to Hook the Container.",
    },
    {
      name: "Arrived to Hook Container",
      description: "Arrived at Hook the Container.",
    },
    {
      name: "Enroute to Deliver Load",
      description: "Enroute to Deliver the Load.",
    },
    {
      name: "Arrived at Deliver Load",
      description: "Arrived at  Deliver Load.",
    },
    {
      name: "Enroute to Return Empty",
      description: "Enroute to Return the Empty Container.",
    },
    {
      name: "Arrived at Return Empty",
      description: "Arrived at Return the Empty Container.",
    },
    {
      name: "Enroute to Return Chassis",
      description: "Enroute to Return the Chassis.",
    },
    {
      name: "Arrived to Return Chassis",
      description: "Arrived at Return the Chassis.",
    },
    {
      name: "Completed",
      description: "The load is Completed.",
    },
    {
      name: "Billing",
      description: "The load is in Billing Status.",
    },
  ],
  statusHeading2: "Export Load Status",
  statusDetails2: [
    {
      name: "Pending",
      description: "The Load is Pending.",
    },
    {
      name: "Available",
      description: "The Load is Available.",
    },
    {
      name: "Dispatched",
      description: "The Load is Dispatched.",
    },
    {
      name: "Enroute to Chassis",
      description: "Enroute to pick up the Chassis.",
    },
    {
      name: "Arrived to Chassis",
      description: "Arrived at pick up the Chassis.",
    },
    {
      name: "Enroute to Pick Container",
      description: "Enroute to Pick up the Container.",
    },
    {
      name: "Arrived at Pick Container",
      description: "Arrived at pick up the Container.",
    },
    {
      name: "Enroute to Drop Container",
      description: "Enroute to Drop the Container.",
    },
    {
      name: "Dropped",
      description: "The container is Dropped.",
    },
    {
      name: "Dropped-Empty",
      description: "Dropped container is empty.",
    },
    {
      name: "Enroute to Hook Container",
      description: "Enroute to Hook the Container.",
    },
    {
      name: "Arrived to Hook Container",
      description: "Arrived at Hook the Container.",
    },
    {
      name: "Enroute to Get Loaded",
      description: "Enroute to Deliver the Load.",
    },
    {
      name:  "Arrived at Get Loaded",
      description: "Arrived at  Deliver Load.",
    },
    {
      name: "Enroute to Return Load",
      description: "Enroute to Return the load Container.",
    },
    {
      name: "Arrived at Return Load",
      description: "Arrived at Return the load Container.",
    },
    {
      name: "Enroute to Return Chassis",
      description: "Enroute to Return the Chassis.",
    },
    {
      name: "Arrived to Return Chassis",
      description: "Arrived at Return the Chassis.",
    },
    {
      name: "Completed",
      description: "The load is Completed.",
    },
    {
      name: "Billing",
      description: "The load is in Billing Status.",
    },
  ],
  statusHeading3: "Road Load Status",
  statusDetails3: [
    {
      name: "Pending",
      description: "The Load is Pending.",
    },
    {
      name: "Available",
      description: "The Load is Available.",
    },
    {
      name: "Dispatched",
      description: "The Load is Dispatched.",
    },
    {
      name: "Enroute to Pick Up",
      description: "Enroute to Pick up the Container.",
    },
    {
      name:  "Arrived at Pick Up",
      description: "Arrived at pick up the Container.",
    },
    {
      name: "Enroute to Drop Trailer",
      description: "Enroute to Drop the trailer.",
    },
    {
      name: "Dropped",
      description: "The container is Dropped.",
    },
    {
      name: "Dropped-Empty",
      description: "Dropped container is empty.",
    },
    {
      name: "Dropped-Loaded",
      description: "Dropped container is loaded.",
    },
    {
      name: "Enroute to Hook Trailer",
      description: "Enroute to Hook the trailer.",
    },
    {
      name: "Arrived to Hook Trailer",
      description: "Arrived at Hook the trailer.",
    },
    {
      name: "Enroute to Deliver Load",
      description: "Enroute to Deliver the Load.",
    },
    {
      name: "Arrived at Deliver Load",
      description: "Arrived at  Deliver Load.",
    },
    {
      name: "Completed",
      description: "The load is Completed.",
    },
    {
      name: "Billing",
      description: "The load is in Billing Status.",
    },
  ],
};
