import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "./components/Common/Layout";
import Documentation from "./pages/documentation";
import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import SignUp from "./pages/login/SignUp";
import Webhooks from "./pages/webhooks/Webhooks";
import OtpVerification from "./pages/login/OtpVerification";
import "./App.css";
import AdminVerification from "./pages/login/AdminVerification";
import SignIn from "./pages/login/SignIn";
import PrivateRoute from "./components/Common/PrivateRoute";
import ApiKeys from "./pages/api-keys/ApiKeys";
import ForgetPassword from "./pages/login/ForgetPassword";
import ResetPassword from "./pages/login/ResetPassword";
import { getProfile } from "./actions/userActions";
import NoMatch from "./components/404/NotMatch";

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
};
export default function App() {

  const { token } = useSelector(state=>state?.user)
  const dispatch = useDispatch();
  useEffect(()=>{
    if(token){
      dispatch(getProfile(token))
    }
  },[])
  return (
        <AlertProvider template={AlertTemplate} {...options}>
          <Router>
            <Switch>
              <Route exact path="/signup">
                <SignUp />
              </Route>
              <Route exact path="/signin">
                <SignIn />
              </Route>
              <Route exact path="/forgot-password">
               <ForgetPassword />
              </Route>
              <Route exact path="/reset-password">
               <ResetPassword />
              </Route>
              <Route exact path="/verify-otp">
                <OtpVerification />
              </Route>
              <Route exact path="/verify-admin">
                <AdminVerification />
              </Route>
                <PrivateRoute exact path="/">
                  <Redirect to="/documentation/introduction" />
                </PrivateRoute>
                <PrivateRoute
                  path="/documentation"
                  component={Documentation}
                />
                <PrivateRoute path="/api-keys" component={ApiKeys} />
                <PrivateRoute
                  path="/webhooks"
                  component={Webhooks}
                />
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Router>
        </AlertProvider>
  );
}
