import React, { useState } from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_PROFILE } from "../../constants/userConstants";

const UpdateProfile = ({ setOpen, open }) => {
  const { name, userEmail, token } = useSelector((state) => state.user);
  const [userName, setUserName] = useState(name);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const UpdateProfile = (e) => {
    setLoading(true);
    const data = {
      name: userName,
    };
    const query = {
      url: `${process.env.REACT_APP_AUTH_URL}/update-profile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      method: "post",
      data: data,
    };
    axios(query)
      .then(() => {
        alert.success("Profile updated Successfully.");
        setOpen(false);
        dispatch({ type: UPDATE_PROFILE, payload: userName });
        setLoading(false);
      })
      .catch((err) => {
        alert.error("Something went wrong.");
        setLoading(false);
        setOpen(false);
      });
  };
  return (
    <Modal show={open} centered>
      <Modal.Header>
        <h4 className="modal-title" id="myModalLabel">
          Profile
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group form-row align-items-center">
          <div className="col-md-3">
            <h5 className="mt-2">Name: </h5>
          </div>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group form-row align-items-center">
          <div className="col-md-3">
            <h5 className="mt-2">Email: </h5>
          </div>
          <div className="col-md-9">
            <input
              type="email"
              className="form-control"
              disabled
              value={userEmail}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn text-gray-500" onClick={() => setOpen(false)}>
          Cancel
        </button>
        <Button
          className="btn btn-lg btn-primary justify-content-center"
          type="submit"
          disabled={loading}
          onClick={UpdateProfile}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>Update</>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProfile;
