import React, { useState } from "react";
import { Route } from "react-router-dom";
import { SideBarLeft } from "../../components/Common/Sidebar";
import { RefreshToken } from "./authentication/RefreshToken";
import { RequestToCompany } from "./carrier/RequestToCompany";
import ApiEnvironment from "./introduction/ApiEnvironment";
import GetAllInvoices from "./invoice/GetAllInvoices";
import GetAllLoads from "./loads/GetAllLoads";
import QuickStartTender from "./tenders/QuickStartTender";
import CreateTender from "./tenders/CreateTender";
import RetrieveTender from "./tenders/RetrieveTender";
import UpdateTender from "./tenders/UpdateTender";
import RetrieveAllTender from "./tenders/RetrieveAllTenders";
import GetAllCarriers from "./carrier/GetAllCarriers";
import AccessToken from "./authentication/AccessToken";
import Introduction from "./introduction/Introduction";
import GenerateCarrierToken from "./authentication/GenerateCarrierToken";
import Errors from "./errors/Error";
import Carrier from "./carrier/Carrier";
import Authentication from "./authentication/Authentication";
import Loads from "../../../src/pages/documentation/loads/Loads";
import Invoices from "../../../src/pages/documentation/invoice/Invoices";
import Caller from "../../../src/pages/documentation/embeddedObjects/Caller";
import { CarrierObj } from "../../../src/pages/documentation/embeddedObjects/CarrierObj";
import Consignee from "../../../src/pages/documentation/embeddedObjects/Consignee";
import { Shipper } from "../../../src/pages/documentation/embeddedObjects/Shipper";
import { Event } from "./embeddedObjects/Event";
import { Price } from "./embeddedObjects/Price";
import { Document } from "./embeddedObjects/Document";
import { Items } from "./embeddedObjects/Items";
import { Expense } from "./embeddedObjects/Expense";
import { Appointments } from "./embeddedObjects/Appointments";
import RetrieveContainerOwners from "./tenders/container/RetrieveContainerOwners";
import RetrieveContainerSizes from "./tenders/container/RetrieveContainerSizes";
import RetrieveContainerTypes from "./tenders/container/RetrieveContainerTypes";
import RetrieveShipper from "./tenders/customer/RetrieveShipper";
import RetrieveConsignee from "./tenders/customer/RetrieveConsignee";
import Webhooks from "./webhooks/Webhooks";
import { Events } from "./webhooks/Events";
import { Sandbox } from "./webhooks/Sandbox";
import GetOneLoad  from "./loads/GetOneLoad"
import GetLoadDocument  from "./loads/GetLoadDocument"
import RetrieveChargeCodes from "./tenders/chargeCodes/RetrieveChargeCodes";
import SetupAPIs from "./introduction/SetupAPI";
import Layout from "../../components/Common/Layout";
import GetRefreshToken from "./authentication/RetrieveRefreshToken";


const Documentation = () => {
  const [selected, setSelected] = useState("INTRODUCTION");
  return (
    <>
    <Layout>
      <SideBarLeft selected={selected} setSelected={setSelected} /> 
      <Route exact path="/documentation/introduction/api-environment">
        <ApiEnvironment />
      </Route>
      <Route exact path="/documentation/introduction">
        <Introduction />
      </Route>
      <Route exact path="/documentation/introduction/api-setup-postman">
        <SetupAPIs />
      </Route>
      <Route exact path="/documentation/authentication">
        <Authentication />
      </Route>
      <Route exact path="/documentation/authentication/generate-refresh-key">
        <RefreshToken />
      </Route>
      <Route exact path="/documentation/authentication/generate-access-key">
        <AccessToken />
      </Route>
      <Route exact path="/documentation/authentication/retrieve-access-key">
        <AccessToken />
      </Route>
      <Route exact path="/documentation/authentication/retrieve-refresh-key">
        <GetRefreshToken />
      </Route>
      <Route exact path="/documentation/authentication/generate-carrier-key">
        <GenerateCarrierToken />
      </Route>
      <Route exact path="/documentation/errors">
        <Errors />
      </Route>
      <Route exact path="/documentation/carrier">
        <Carrier />
      </Route>
      <Route exact path="/documentation/carrier/get-all-carriers">
        <GetAllCarriers />
      </Route>
      <Route exact path="/documentation/carrier/request-to-company">
        <RequestToCompany />
      </Route>
      <Route exact path="/documentation/invoices/retrieve-all-invoices">
        <GetAllInvoices />
      </Route>
      <Route exact path="/documentation/loads/retrieve-all-loads">
        <GetAllLoads />
      </Route>
      <Route exact path="/documentation/loads/get-load-detail">
        <GetOneLoad />
      </Route>
      <Route exact path="/documentation/loads/get-load-document">
        <GetLoadDocument />
      </Route>
      <Route exact path="/documentation/tenders">
        <QuickStartTender/>
      </Route>
      <Route exact path="/documentation/tenders/quick-start">
        <QuickStartTender/>
      </Route>
      <Route exact path="/documentation/tenders/create-tender">
        <CreateTender/>
      </Route>
      <Route exact path="/documentation/tenders/retrieve-all-charge-codes">
        <RetrieveChargeCodes/>
      </Route>
      <Route exact path="/documentation/tenders/retrieve-all-container-owners">
        <RetrieveContainerOwners/>
      </Route>
      <Route exact path="/documentation/tenders/retrieve-all-container-sizes">
        <RetrieveContainerSizes/>
      </Route>
      <Route exact path="/documentation/tenders/retrieve-all-container-types">
        <RetrieveContainerTypes/>
      </Route>
      {/* <Route exact path="/documentation/tenders/retrieve-all-shipper">
        <RetrieveShipper/>
      </Route>
      <Route exact path="/documentation/tenders/retrieve-all-consignee">
        <RetrieveConsignee/>
      </Route> */}
      <Route exact path="/documentation/tenders/retrieve-tender">
        <RetrieveTender/>
      </Route>
      <Route exact path="/documentation/tenders/update-tender">
        <UpdateTender/>
      </Route>
      <Route exact path="/documentation/tenders/retrieve-all-tenders">
        <RetrieveAllTender/>
      </Route>

      <Route exact path="/documentation/loads">
        <Loads />
      </Route>
      <Route exact path="/documentation/invoices">
        <Invoices />
      </Route>
      <Route exact path="/documentation/embedded-objects/caller">
        <Caller />
      </Route>
      <Route exact path="/documentation/embedded-objects/carrier">
        <CarrierObj />
      </Route>
      <Route exact path="/documentation/embedded-objects/consignee">
        <Consignee />
      </Route>
      <Route exact path="/documentation/embedded-objects/shipper">
        <Shipper />
      </Route>
      <Route exact path="/documentation/embedded-objects/events">
        <Event />
      </Route>
      <Route exact path="/documentation/embedded-objects/pricing">
        <Price />
      </Route>
      <Route exact path="/documentation/embedded-objects">
        <Caller />
      </Route>
      <Route exact path="/documentation/embedded-objects/appointments">
        <Appointments />
      </Route>
      <Route exact path="/documentation/embedded-objects/expense">
        <Expense />
      </Route>
      <Route exact path="/documentation/embedded-objects/items">
        <Items />
      </Route>
      <Route exact path="/documentation/embedded-objects/document">
        <Document />
      </Route>
      <Route exact path="/documentation/webhooks">
        <Webhooks />
      </Route> 
      <Route exact path="/documentation/webhooks/events">
        <Events />
      </Route> 
      <Route exact path="/documentation/webhooks/sandbox">
        <Sandbox />
      </Route>
      </Layout>
    </>
  );
};

export default Documentation;
