import React from 'react'
import DynamicComponent from '../../../components/Common/DynamicComponent/DynamicComponent'
const data = {
    title: "Retrieve All Invoices",
    subTitle: "This endpoint retrieve all invoices.",
    requestLink: "GET /invoice",
    title2: "API Setup",
    requestLink2: "Authorization: Bearer <Your Carrier Token>",
    queryParameters: [
      {
        parameter: "referenceNumber",
        type: "String",
        description: "Reference number of load"
      },
      {
        parameter: "invoiceNumbers",
        type: "Array[string]",
        description: "Array of reference numbers."
      },
      {
        parameter: "skip",
        type: "Int[int32]",
        description: "Skip invoices count"
      },
      {
        parameter: "limit",
        type: "Int[int32]",
        description: "Limit on invoices count page"
      },
      {
        parameter: "isSubCustomer",
        type: "Boolean",
        description: "Whether to show load invoices that are not based on the primary charge set of the load and are not consolidated into combined invoices. These invoices were historically referred to as subcustomer invoices."
      },
      {
        parameter: "billingFrom",
        type: "Date/string",
        description: "Billing from date of invoice for filter."
      },
      {
        parameter: "billingTo",
        type: "Date/string",
        description: "Billing to date of invoice for filter."
      }
    ]
  };
  
  const jsonContent = `{
    "_object": "/api/response",
    "self": "/v1/invoice",
    "version": "1.0.0",
    "data": [<Invoice Object>, <Invoice Object>],
    "error": null
 }
 `;

const GetAllInvoices = () => {
  return (
    <div><DynamicComponent props={data} content={jsonContent}/></div>
  )
}

export default GetAllInvoices