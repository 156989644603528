import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, resendOtp, verifyOtp } from "../../actions/userActions";
import { useHistory, useLocation } from "react-router-dom";
import { IconLogo } from "../../components/Common/Icons";

const OtpVerification = () => {
  const [otp, setOtp] = useState({
    opt1: "",
    opt2: "",
    opt3: "",
    opt4: "",
    opt5: "",
    opt6: "",
  });
  const dispatch = useDispatch();
  let history = useHistory();
  const { error, userEmail, isVerified, loading } = useSelector(
    (state) => state.user
  );
  const alert = useAlert();
  const [count, setCount] = useState(0);
  const [otpFlag, setOtpFlag] = useState(false);
  const location = useLocation();
  const [fullOtp,setFullOtp] = useState();
  const handleChange = (value1, event) => {
    setOtp({ [value1]: event.target.value });
  };
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt?.target?.tabIndex - 2;
      if (next > -1) {
        elmnt?.target?.form?.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt?.target?.form?.elements[next].focus();
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    let fullOtp = "";
    for (const pair of data.entries()) {
      fullOtp = fullOtp + `${pair[1]}`;
    }
    setFullOtp(+fullOtp)
    const sendOtp = {
      otp: +fullOtp,
      email: userEmail,
    };
    if(location?.state && location?.state?.isForgot){
      sendOtp.isForgot = true;
      sendOtp.email = location?.state?.email
    }
    // if (sendOtp.otp.length !== 6) {
    //     alert.error("Please enter valid OTP")
    //     return;
    // }
    dispatch(verifyOtp(sendOtp));
    if (!error) {
      setOtpFlag(true);
    }
  };

  const handleResend = () => {
    if (!userEmail && !location?.state?.email) {
      alert.error("Something went wrong.");
      return;
    }
    if(location?.state?.email){
      count === 0 && dispatch(resendOtp(location?.state?.email,location?.state?.isForgot));
    }
    else{
      count === 0 && dispatch(resendOtp(userEmail));
    }
    if (!error && count === 0) {
      alert.show("OTP sent Successfully");
      setCount(30);
    }
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isVerified && otpFlag) {
      alert.success("OTP verified Successfully.")
      history.push("/signin");
      setOtpFlag(false);
    }
    if (isVerified && otpFlag && (location.state && location.state.isForgot)) {
      history.push("/reset-password",{ otp: fullOtp, email: location.state.email });
      setOtpFlag(false);
    }
  }, [error, isVerified, otpFlag, alert, dispatch, history]);

  useEffect(() => {
    if (count !== 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
  }, [count]);

  return (
      <div className="signup-wrapper h-100 bg-gray-50">
          <div className="row no-gutters d-flex min-vh-100">
            <div className="col-lg-12 col-xl-12">
              <div className="w-100 d-flex justify-content-center mt-50">
                <div className="">
                  <IconLogo />
                  <div className="bg-dark p-10 text-center text-white font-12 text-uppercase letter-spacing-5">
                    Developers
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-xl-3"></div>
            <div className="col-lg-6 col-xl-6">
              <form
                onSubmit={handleSubmit}
                className="d-flex justify-content-center flex-column align-items-center"
              >
                <h1>Verify OTP</h1>
                <div className="otpContainer">
                  <input
                    name="otp1"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp.otp1}
                    onChange={(e) => handleChange("otp1", e)}
                    tabIndex="1"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    name="otp2"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp.otp2}
                    onChange={(e) => handleChange("otp2", e)}
                    tabIndex="2"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    name="otp3"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp.otp3}
                    onChange={(e) => handleChange("otp3", e)}
                    tabIndex="3"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    name="otp4"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp.otp4}
                    onChange={(e) => handleChange("otp4", e)}
                    tabIndex="4"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />

                  <input
                    name="otp5"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp.otp5}
                    onChange={(e) => handleChange("otp5", e)}
                    tabIndex="5"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    name="otp6"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp.otp6}
                    onChange={(e) => handleChange("otp6", e)}
                    tabIndex="6"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <div className="text-end mr-15 mt-15">
                      <p
                        className={`font-14 ${count === 0 ? "pointer" : ""}`}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          color: "hsl(218, 91%, 59%)",
                        }}
                        onClick={() => count === 0 && handleResend()}
                      >
                        {" "}
                        {count === 0 ? "Resend OTP" : `Resend OTP in ${count}s`}
                      </p>
                  </div>
                </div>
                <div>
                  <Button
                    className="btn btn-primary font-12 pr-30 pl-30 mt-2"
                    type="submit"
                    disabled={loading}
                  >
                    Verify {loading && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>}
                  </Button>
                </div>
              </form>
            </div>
            <div className="col-lg-3 col-xl-3"></div>
          </div>
        </div>
  );
};

export default OtpVerification;
