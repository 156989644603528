import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
    title: "Shipper",
    subTitle: "Shipper object matrix",
    customName:" ",
    queryParameters: [
      {
        parameter: "shipperId",
        type: "string",
        description: "Unique identifier for the Shipper object",
      },
      {
        parameter: "shipperName",
        type: "string",
        description: "Name of Shipper",
      },
      {
        parameter: "email",
        type: "string",
        description: "Email of Shipper",
      },
      {
        parameter: "address",
        type: "object",
        description: "Address object of Shipper",
      },
      {
        parameter: "address1",
        type: "string",
        description: "Address of Shipper",
      },
      {
        parameter: "city",
        type: "string",
        description: "City name of Shipper",
      },
      {
        parameter: "state",
        type: "string",
        description: "State name of Shipper",
      },
      {
        parameter: "zip",
        type: "string",
        description: "Zip code of Shipper",
      },
      {
        parameter: "country",
        type: "string",
        description: "Country name of Shipper",
      },
    ],
  };
const jsonContent = `{
    "shipperId": "GCTBAYONNE9648",
    "shipperName": "GCT BAYONNE",
    "email": "r56lg6hw96h678r@portpro.io",
    "address": {
        "address1": "302 Port Jersey Blvd",
        "city": "Jersey City",
        "state": "NJ",
        "zip": "",
        "country": "US"
    }`;

export const Shipper = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};
