import React from 'react'
import DynamicComponent from "../../../../components/Common/DynamicComponent/DynamicComponent";
import chargeCodesData from './../constants/chargeCodes/chargeCodes.json' 
const data = {
  title: "Retrieve All ChargeCodes",
  subTitle: "This endpoint retrieves all valid charge codes. You can use this to create pricing line items in a tendered load(s).",
  requestLink: "GET  /tenders/charge-codes",
  queryParameters: null,
  requestParameters: null,
  note: "when creating a tender or updating tender send the chargeCodes name in the pricing name field",
  remember: "you can create a test tender to see where the Base Price is being applied when sending price to create a Tender",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
};

const jsonContent = JSON.stringify(chargeCodesData,null,4)

const RetrieveChargeCodes = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  )
}

export default RetrieveChargeCodes