import React from 'react'
import DynamicComponent from "../../../../components/Common/DynamicComponent/DynamicComponent";
import retrieveShipperData from './../constants/customers/retrieveShipper.json' 
const data = {
  title: "Retrieve All Shippers",
  subTitle: "This endpoint retrieve all consignees you can use to update tender.",
  requestLink: "GET  /tenders/shipper",
  queryParameters: null,
  requestParameters: null,
  note: "when creating a tender or updating tender send the shipper name in the shipperValue",
  remember: "You can also send the shipperAddressValue along with the shipperValue if the shipperNames are not unique",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
};

const jsonContent = JSON.stringify(retrieveShipperData,null,4)

const RetrieveShipper = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  )
}

export default RetrieveShipper