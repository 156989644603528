import React from 'react'
import DynamicComponent from "../../../../components/Common/DynamicComponent/DynamicComponent";

import retrieveContainerTypesData from './../constants/container/containerTypes.json' 
const data = {
  title: "Retrieve All Container Types",
  subTitle: "This endpoint retrieves all container types. You can use this to update relevant information in your system.",
  requestLink: "GET  /tenders/container-types",
  queryParameters: null,
  requestParameters: null,
  note: "When creating a tender or updating tender sending the container Type name in the containerType will assign containerType to a Tender",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
};

const content = JSON.stringify(retrieveContainerTypesData,null,4)

const RetrieveContainerTypes = () => {
  return (
    <div>
      <DynamicComponent props={data} content={content}/>
    </div>
  )
}

export default RetrieveContainerTypes