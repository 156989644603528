export const routesData = [
  {
    title: "INTRODUCTION",
    childrens: [
      {
        title: "API Environment",
        route: "api-environment",
      },
      {
        title: "Setup APIs in Postman",
        route: "api-setup-postman",
      },
    ],
    route: "introduction",
  },
  {
    title: "AUTHENTICATION",
    // childrens: ["Quick Start", "Guides", "Reference"],
    childrens: [
      {
        title: "Generate Access Token",
        route: "generate-access-key",
      },
      {
        title: "Generate Carrier Token",
        route: "generate-carrier-key",
      },
      {
        title: "Retrieve Refresh Token",
        route: "retrieve-refresh-key",
      },
    ],
    route: "authentication",
  },
  {
    title: "ERRORS",
    childrens: [
    ],
    route: "errors",
  },
  {
    title: "CARRIER",
    // childrens: ["Get All Carriers", "Create Request to Company", "Generate Carrier Token"],
    childrens: [
      {
        title: "Get All Carriers",
        route: "get-all-carriers",
      },
      {
        title: "Create Request to Company",
        route: "request-to-company",
      }
    ],
    route: "carrier",
  },
  {
    title: "INVOICES",
    // childrens: ["Quick Start", "Guides", "Reference"],
    childrens: [
      {
        title: "Retrieve All Invoices",
        route: "retrieve-all-invoices",
      },
    ],
    route: "invoices",
  },
  {
    title: "LOADS",
    // childrens: ["Quick Start", "Guides", "Reference"],
    childrens: [
      {
        title: "Retrieve All Loads",
        route: "retrieve-all-loads",
      },
      {
        title: "Retrieve Specific Load",
        route: "get-load-detail",
      },
      {
        title: "Retrieve Load Documents",
        route: "get-load-document",
      },
    ],
    route: "loads",
  },
  {
    title: "WEBHOOKS",
    // childrens: ["Quick Start", "Guides", "Reference"],
    childrens: [
      {
        title: "Events",
        route: "events",
      },
      {
        title: "Sandbox",
        route: "sandbox"
      }
    ],
    route: "webhooks",
  },
  {
    title:"TENDERS",
    route:"tenders",
    childrens: [
      {
        title: "Quick Start",
        route: "quick-start",
      },
      {
        title: "Create Tender",
        route: "create-tender",
      },
      {
        title: "Retrieve Tender",
        route: "retrieve-tender",
      },
      {
        title: "Update Tender",
        route: "update-tender",
      },
      {
        title: "Retrieve All Tenders",
        route: "retrieve-all-tenders",
      },
      {
        title: "Retrieve All Charge Codes",
        route: "retrieve-all-charge-codes",
      },
      // {
      //   title: "Retrieve All Shippers",
      //   route: "retrieve-all-shipper",
      // },
      // {
      //   title: "Retrieve All Consignees",
      //   route: "retrieve-all-consignee",
      // },
      {
        title: "Retrieve All Container Owners",
        route: "retrieve-all-container-owners",
      },
      {
        title: "Retrieve All Container Sizes",
        route: "retrieve-all-container-sizes",
      },
      {
        title: "Retrieve All Container Types",
        route: "retrieve-all-container-types",
      },
    ],
  },
  {
    title: "EMBEDDED OBJECTS",
    // childrens: ["Quick Start", "Guides", "Reference"],
    childrens: [
      
      {
        title: "Caller",
        route: "caller",
      },
      {
        title: "Carrier",
        route: "carrier",
      },
      {
        title: "Consignee",
        route: "consignee",
      },    
      {
        title: "Shipper",
        route: "shipper",
      },
      {
        title: "Appointments",
        route: "appointments",
      }, 
      {
        title: "Document",
        route: "document",
      },

      {
        title: "Expense",
        route: "expense",
      },
      {
        title: "Events",
        route: "events",
      },

      {
        title: "Items",
        route: "items",
      },

      {
        title: "Pricing",
        route: "pricing",
      },
       
    ],
    route: "embedded-objects",
  }
];
