import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createOrUpdateWebhook, generateWebhookToken, getWebhookDetails, getWebhookToken } from "../../actions/apiKeyActions";
import { clearErrors } from "../../actions/userActions";
import Loader from "../../components/Common/Loader/Loader";
import { startCase, toLower, uniq, xor } from "lodash";
import confirm from "../../components/Common/Modal/Modal";
import { UPDATE_WEBHOOK_RESET } from "../../constants/apiKeyConstants";
import Layout from "../../components/Common/Layout";
import History from "./History";

const star = "*";

const events =
{
  load_tender_event: [
    { label: "Tender Status Change", value: "tendor#status_changed" },
    { label: "Load Document Added", value: "load#document_added" },
    { label: "Load Status Change", value: "load#status_updated" },
    { label: "Load Notes Change", value: "load#notes" }
  ],
  milestone_event: [
    { label: "Load Date Sync", value: "load#dates_updated"}
  ],
  routing_event: [
    { label: "Return container", value: "load#return_container" },
    { label: "Deliver load", value: "load#deliver_load" },
    { label: "Pull container", value: "load#pull_container" },
    { label: "Chassis pick", value: "load#chassis_pick" },
    { label: "Chassis termination", value: "load#chassis_termination" },
    { label: "Drop container", value: "load#drop_container"},
    { label: "Hook container", value: "load#hook_container" }
  ],
  appointment_event: [
    { label: "Port pickup", value: "appointments#port_pickup" },
    { label: "Consignee", value: "appointments#consignee" },
    { label: "Port return", value: "appointments#port_return" },
  ],
  invoice_event: [
    { label: "Invoice created", value: "load#invoice_created" },
  ]
}

const validURL = (str) => {
  // eslint-disable-next-line
  var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null)
}

const WebhookPage = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { token } = useSelector((state) => state.user);
  const { webhookToken, loading, error, eventFrom, webhookUrls, isWebhook, getLoading } = useSelector(
    (state) => state.apiKey,
    shallowEqual
  );
  const [isHide, setIsHide] = useState(true);
  const [eventType, setEventType] = useState([]); // eslint-disable-next-line
  const [displayData, setDisplayData] = useState(events)
  const [webhookUrl, setWebhookUrl] = useState("");
  const [tab, setTab] = useState("details");

  const handleCopyButton = (data) => {
    const el = document.createElement("textarea");
    el.value = data ? webhookToken : webhookToken;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  const addWebhooks = () => {
    let valid = validURL(webhookUrl)
    if (!valid) { alert.error("Enter Valid Url"); return }
    let data = {
      webhookUrl: webhookUrl,
      eventType: eventType,
    }
    dispatch(createOrUpdateWebhook(token, data))
  }
  const handleGenerateToken = () => {
    webhookToken !== "" ? confirm("Are you sure, you want to regenerate webhook token?", "If you regenerate webhook token your previous webhook token will be invalid.", (confirm) => {
      if (!confirm) return
      dispatch(generateWebhookToken(token));
    }) : dispatch(generateWebhookToken(token));
  }
  useEffect(() => {
    dispatch(getWebhookToken(token, "sandbox"));
    dispatch(getWebhookDetails(token))
    return () => {
      dispatch({ type: "WEBHOOK_TOKEN_CLEAR" });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEventType(eventFrom || [])
    setWebhookUrl(webhookUrls || "")
    // eslint-disable-next-line
  }, [webhookUrls, eventFrom]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  },[error, alert])
  useEffect(() => {
    if (isWebhook) {
      alert.success("Webhook updated Successfully.")
      dispatch({ type: UPDATE_WEBHOOK_RESET })
    }
    // eslint-disable-next-line
  }, [isWebhook])

  return (
    <>
    <Layout>
      {loading || getLoading ? <Loader /> : null}
      <div className="p-25">
      <div className="filter-row d-flex align-items-center my-2 pt-3">
          <ul className="nav nav-compact" role="tablist">
          <li className="nav-item">
              <span
                className={`nav-link ${tab === "details"
                  ? "active"
                  : "inactive"
                  } pointer`}
                role="tab"
                onClick={() => { setTab("details") }}
              >
                Setup
              </span>
            </li>
            <li className="nav-item">
              <span
                className={`nav-link ${tab === "history"
                  ? "active"
                  : "inactive"
                  } pointer`}
                role="tab"
                onClick={() => { setTab("history") }}
              >
                History
              </span>
            </li>
          </ul>
        </div>
        {tab==="details"?
        <div className="card p-20 mt-15 border-0">
          <h3 className="font-16 mb-20 mt-15 font-bold">Webhooks Setting</h3>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Generate refresh token"
              className="form-control"
              style={{ backgroundColor: "white", color: "black" }}
              disabled={true}
              value={
                !webhookToken
                  ? ""
                  : isHide
                    ? star.repeat(200)
                    : webhookToken
              }
              id="webhookToken"
            />
            <button
              onClick={() => setIsHide(!isHide)}
              type="button"
              className="btn btn-info text-nowrap mx-2"
            >
              {isHide ? (
                <i className="uil uil-eye"></i>
              ) : (
                <i className="uil uil-eye-slash"></i>
              )}
            </button>

            <button
              className="btn btn-info text-nowrap"
              onClick={() => handleCopyButton("data")}
            >
              <i className="uil uil-copy"></i>
            </button>
          </div>
          <div>
            <button className="btn btn-primary mt-3 d-flex" onClick={handleGenerateToken}>
              {webhookToken && webhookToken === "" ? (
                <span>Generate</span>
              ) : (
                <span>Regenerate</span>
              )}
            </button>
          </div>
          <div>
            <div className="mt-4">
              <label className="mt-2"><h3 className="font-16 mb-20 mt-15 font-bold">Webhook Endpoint URL</h3></label>
              <input type="text" className="form-control" placeholder="Enter URL" onChange={(e) => setWebhookUrl(e.target.value)} value={webhookUrl && webhookUrl === "" ? "" : webhookUrl} />
              <label className="mt-20"><h3 className="font-16 mb-20 mt-20 font-bold">Events</h3></label>
              <div className="my-1">
                <div className="form-row">
                  {displayData && Object.keys(displayData).map((event, key) => {
                    return (
                      <div className="col" key={key}>
                        <h6 className="font-size-regular text-gray-500 mb-10">
                          {startCase(toLower(event))}
                        </h6>
                        {!Array.isArray(displayData[event]) && (
                          <React.Fragment>
                            <div className="form-check form-check">
                              <label
                                className="form-check-label"
                                htmlFor="isAll"
                              >
                                {startCase(toLower(event))}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        <div className="d-flex flex-column">
                          {Array.isArray(displayData[event]) && (
                            <React.Fragment>
                              <div className="form-check pl-0 form-check mb-1">
                                <input
                                  className="input-control mr-2"
                                  type="checkbox"
                                  name={event}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      const checked = e.target.name
                                      const selectData = displayData[checked].map(el => el.value)
                                      const alldata = eventType.concat(selectData)
                                      const uniqData = uniq(alldata)
                                      setEventType(uniqData)
                                    } else {
                                      const checked = e.target.name
                                      const data1 = displayData[checked].map(el => el.value)
                                      const eventTypes = xor(eventType, data1)
                                      setEventType(eventTypes)
                                    }
                                  }}
                                  checked={!displayData[event].find((d) => eventType && !eventType.includes(d.value))}

                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isAll"
                                >
                                  All
                                </label>
                              </div>
                            </React.Fragment>
                          )}
                          {Array.isArray(displayData[event]) &&
                            displayData[event].map((d, i) => {
                              let name = d.label;
                              return (
                                <div className="form-check pl-0 form-check mb-1" key={i}>
                                  <input
                                    className="input-control mr-2"
                                    type="checkbox"
                                    name={d.value}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setEventType([...eventType, e.target.name])
                                      } else {
                                        const eventTypes = xor(eventType, [e.target.name])
                                        setEventType(eventTypes)
                                      }
                                    }}
                                    checked={eventType.includes(d.value)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`check${key}_${d}`}
                                  >
                                    {startCase(toLower(name))}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={() => addWebhooks()}
            disabled={loading || (webhookUrl === "")}
            className="mr-auto btn btn-primary text-nowrap my-2"
          >
            {loading && (
              <i className="uil uil-spinner-alt mr-1"></i>
            )}
            {"Save Webhook"}
          </button>
        </div>:
        <History token={token}/>
        } 
      </div>
      </Layout>
    </>
  )
}

export default WebhookPage