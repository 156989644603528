import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Generate Refresh Token",
  subTitle: "This endpoint will generate a new access token.",
  requestLink: "GET  /token/generate-refresh",
  queryParameters: [
    {
      parameter: "environment",
      type: "String[“sandbox”,“production”]",
      description: "In which environment the token will be going to use",
    },
  ],
  note: "You can use refresh key in authorization in request header. Refresh Key has 100 Days validity."
};

const jsonContent = `{
    "_object": "/api/response",
    "self": "/v1/token/generate-refresh",
    "version": "1.0.0",
    "data": {
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmQ4ZjhhYzZkNmE1OTIzZmE0NDBhMzMiLCJpYXQiOjE2NTg0"
    },
    "error": null
 }`;

export const RefreshToken = () => {
  return (
    <div>
      <DynamicComponent  props={data} content={jsonContent}/>
    </div>
  );
};
