import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";
import retrieveTenders from './constants/retrieveTenders.json'
import { TENDER_CONSTANTS } from "./constants/tender/tender.constants";

const commonPayload = [{
  parameter: "tenderReferenceNumber",
  type: "string",
  description: "tenderReferenceNumber of Tender"
},
{
  parameter:'tenderReferenceNumbers',
  type:"string or array[string] separated by ,",
  description:'Tender Reference Numbers',
},
{
  parameter:'limit',
  type:'number',
  description:'Number of records to retrieve',
},
{
  parameter:'skip',
  type:'number',
  description:'Number of records to skip',
},
{
  parameter:'sortBy',
  type:'string',
  description:'Sort by field One of: [createdAt,updatedAt,tenderReferenceNumber]',
},
{
  parameter:'sortOrder',
  type:'string',
  description:'Sort order One of:[asc,desc]',
},
{
  parameter:'typeOfLoad',
  type:'string',
  description:'Type of the load. One of: [IMPORT, EXPORT, ROAD]',
},
{
  parameter:'preSetOrderStatus',
  type:'string',
  description:`Type of the order status. One of: ${TENDER_CONSTANTS.preSetOrderStatus}`,
},
{
  parameter:'deliveryOrderNo',
  type:'string',
  description:'Delivery order no of load',
},
{
  parameter:'callerbillLandingNo',
  type:'string',
  description:'Master Bill of Lading of load',
},
{
  parameter:'secondaryReferenceNo',
  type:'string',
  description:'Secondary reference number of load',
},
{
  parameter:'doNo',
  type:'string',
  description:'House Bill of Lading of load',
},
{
  parameter:'bookingNo',
  type:'string',
  description:'Booking no of load',
},
{
  parameter:'sealNo',
  type:'string',
  description:'Seal number of load',
},
{
  parameter:'purchaseOrderNo',
  type:'string',
  description:'Purchase order number of load',
},
{
  parameter:'workOrderNo',
  type:'string',
  description:'Work order number of load',
},
{
  parameter:'shipmentNo',
  type:'string',
  description:'Shipment number of load',
},
{
  parameter:'acceptRejectCode',
  type:'string',
  description:'Code for retrieving One of:[Pending , Accepted, Rejected]',
},
]
const data = {
  title: "Retrieve All Tenders",
  subTitle: "This endpoint retrieves all Tenders.",
  requestLink: "GET   /tenders/",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",

  queryParameters: [
     ...commonPayload
  ],
  // requestParameters: [
  //   ...commonPayload
  // ],
  // note: "test Notes",
  // remember: "test remember",
};

const jsonContent = JSON.stringify(retrieveTenders,null,4)

const RetrieveAllTenders = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  );
};

export default RetrieveAllTenders;
