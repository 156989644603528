import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import { userReducer } from "./reducers/userReducers";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from 'redux-persist/lib/storage'
import { apiKeyReducer } from "./reducers/apiKeyReducer";

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["form"]
}

const persistKeyConfig = {
  key: 'rootkey',
  storage,
  blacklist: ["form"]
}

const reducers = combineReducers({
  user: persistReducer(persistConfig, userReducer),
  apiKey: persistReducer(persistKeyConfig, apiKeyReducer)
})

const middleware = [thunk];

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
);

const persistor = persistStore(store)

// eslint-disable-next-line
export default () => {
  return { store, persistor }
}