import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import configs from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
const { persistor, store } = configs();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App />
    </PersistGate>

    </Provider>
  </React.StrictMode>
);
