import React, { useState } from "react";
import { HeaderTopBar } from "../Common/Header";
import { NavbarMenu } from "../Common/Header/NavBarMenu";

const Layout = ({ children }) => {
  const[tab,setTab] = useState(1)
  return (
    <div className="customer-developer-layout">
      <HeaderTopBar />
      <NavbarMenu tab={tab} setTab={setTab} />
      {children}
    </div>
  );
};

export default Layout;
