import React from "react";

const Avatar = ({ children , className }) => {
  return (
    <div className={`avatar-circle ${className}`}>
      <div className="avatar-circle__initial ">{children}</div>
    </div>
  );
};

export default Avatar;
