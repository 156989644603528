import React from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

const CustomIconAlert = ({ className }) => (
    <svg
        className={`cicon ${className}`}
        width="50"
        height="41"
        viewBox="0 0 50 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.828345 33.4867L20.5954 2.2016C22.7469 -0.733866 27.2517 -0.733866 29.4032 2.2016L49.1703 33.4867C51.2546 36.2917 49.1703 40.2709 45.6068 40.2709H4.45903C0.828345 40.2056 -1.25594 36.2917 0.828345 33.4867Z"
            fill="url(#paint0_linear)"
        />
        <path
            d="M24.9995 10.2109C23.1159 10.2109 21.6509 11.8668 21.7904 13.8106L22.8369 25.4734C22.9066 26.6253 23.8833 27.5612 24.9995 27.5612C26.1157 27.5612 27.0924 26.6253 27.1621 25.4734L28.2086 13.8106C28.3481 11.7948 26.8831 10.2109 24.9995 10.2109Z"
            fill="white"
        />
        <path
            d="M24.9997 29.7209C23.6045 29.7209 22.4883 30.8728 22.4883 32.3127C22.4883 33.7525 23.6045 34.9044 24.9997 34.9044C26.395 34.9044 27.5112 33.7525 27.5112 32.3127C27.5112 30.8728 26.395 29.7209 24.9997 29.7209Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="-0.0228992"
                y1="21.1943"
                x2="50.0091"
                y2="21.1943"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B0BACC" />
                <stop offset="1" stopColor="#969EAE" />
            </linearGradient>
        </defs>
    </svg>
);

const confirm = (title, message, callback, options) => {
    confirmAlert({
        title,
        message,
        customUI: ({ title, message, onClose }) => {
            const showInputText =
                options && options.showInputText ? options.showInputText : false;
            const showCheckBox =
                options && options.showCheckBox ? options.showCheckBox : false;

            return (
                <div className="custom-ui card">
                    <div className={`modal-header ${showInputText && 'd-none'}`}>
                        <h4 className="modal-title" id="myModalLabel">
                            {title}
                        </h4>
                    </div>

                    <div className="modal-body">
                        <div className={`${!showInputText && 'text-center'}`}>
                            <div className="mb-30">
                                {!showInputText && (
                                    <CustomIconAlert />
                                )}

                            </div>
                            {showInputText &&
                                <h4 className="modal-title mb-1">{title}</h4>
                            }
                            <p className="text-muted font-14">{message}</p>
                            {showInputText && (
                                <div className="form-group w-150 mt-20">
                                    <input
                                        type="number"
                                        className="form-control"
                                        defaultValue="1"
                                        min="1"
                                        max="50"
                                        id="count"
                                        style={{ width: 150 }}
                                    />
                                </div>

                            )}
                            {showCheckBox && (
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="carryOverPricing"
                                    />
                                    <label
                                        className="form-check-label"
                                        style={{ overflowWrap: "break-word" }}
                                        htmlFor="carryOverPricing"
                                    >
                                        Do Not Carry Over Pricing
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn text-gray-500"
                            onClick={() => {
                                callback(false);
                                onClose();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                callback(
                                    true,
                                    showInputText ? document.getElementById("count").value : null,
                                    showCheckBox
                                        ? !document.getElementById("carryOverPricing").checked
                                        : null
                                );
                                onClose();
                            }}
                        >
                            {showInputText ? 'Confirm' : 'Regenerate'}
                        </button>
                    </div>
                </div>
            );
        },
    });
};

export default confirm;
