import React from 'react'

import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Generate Access Token",
  subTitle: "This endpoint will generate a new access token.",
  requestLink: `GET ${process.env.REACT_APP_AUTHENTICATION_URL}/generate-access`,
  title2: "API Setup",
  requestLink2: "Authorization: <Your Refresh Token>",
  queryParameters: [
    {
      parameter: "environment",
      type: "String['sandbox', 'production']",
      isRequired: true,
      description: "In which environment the token will be going to use"
    }
  ],
  note: "You can use access key in authorization in request header. Access Key has 24 Hrs of validity.",
};

const jsonContent = `{
  "_object": "/api/response",
  "self": "/v1/token/generate-access",
  "version": "1.0.0",
  "data": {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmQ4ZjhhYzZkNmE1OTIzZmE0NDBhMzMiLCJpYXQiOjE2NTg0NzczOTgsImV4cCI6MTY1ODU2Mzc5OH0.Z9GGfzOpyqWTbSTIqFHsMJBu_nu3YDibwpt7ZLsjz5o"
  },
  "error": null
}
`;
const AccessToken = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  )
}

export default AccessToken
