import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Pricing",
  subTitle: "Pricing object matrix",
  customName: " ",
  queryParameters: [
    {
      parameter: "name*",
      type: "string",
      description: "Name of Pricing ",
      isRequired: true
    },
    {
      parameter: "perType*",
      type: "string",
      description: "Per Type of item One of:[fixed]",
      isRequired: true
    },
    {
      parameter: "amount*",
      type: "int32",
      description: "Amount of item",
      isRequired: true
    },
    {
      parameter: "chargeType",
      type: "string",
      description: "Type of charge ,not required for creating or updating",
    },
    {
      parameter: "freeDays",
      type: "int32",
      description: "Total Number of Free Days of item ",
    },
    {
      parameter: "unit",
      type: "int32",
      description: "Units of item ",
    },
    {
      parameter: "finalAmount",
      type: "int32",
      description: "Final amount amount of item",
    },
    {
      parameter: "chargePerDay",
      type: "int32",
      description: "Charge per day of item",
    },
  ],
};
const jsonContent = ` {
    "chargeType": "DYNAMIC_PRICING",
    "name": "Chassis",
    "perType": "perday",
    "freeDays": 0,
    "unit": "1.00",
    "finalAmount": "85.00",
    "chargePerDay": 85,
    "amount": "85.00",
}`;

export const Price = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};
