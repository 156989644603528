import {
  CLEAR_ERRORS,
  OTP_FAIL,
  OTP_REQUEST,
  OTP_SUCCESS,
  RESEND_OTP_FAIL,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  SIGNIN_FAIL,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
} from "../constants/userConstants";
import { apiKeyInitialState } from "./apiKeyReducer";
import storage from 'redux-persist/lib/storage'

export const userInitialState = {
  isAuthenticated: false,
  userEmail: "",
  isVerified: false,
  isAdminVerified: false,
  error: null,
  token: "",
  isSignUp: false,
  loading: false,
  name: "",
};

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
    case SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        userEmail: action?.email,
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        isAdminVerified: true,
        isVerified: true,
        userEmail: action?.email,
        token: action?.token,
        name: action?.name,
      }
    case SIGNUP_FAIL:
    case SIGNIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action?.error,
      };
    case OTP_REQUEST:
    case RESEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        isVerified: false,
      };
    case OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isVerified: true,
      };
    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case OTP_FAIL:
    case RESEND_OTP_FAIL:
      return {
        ...state,
        loading: false,
        isVerified: false,
        error: action?.error,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    
    case UPDATE_PROFILE:
      return {
        ...state,
        name: action?.payload
      }
      case UPDATE_PROFILE_FAIL:
        return {
          ...state,
          error: action.error
        }
    case "USER_LOGOUT":
      storage.removeItem('persist:root')
      storage.removeItem('persist:rootkey')
      return Object.assign({}, state, userInitialState, apiKeyInitialState)

    default:
      return state;
  }
};
