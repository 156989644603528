import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Consignee",
  subTitle: "Consignee object matrix",
  customName:" ",
  queryParameters: [
    {
      parameter: "consigneeId",
      type: "string",
      description: "Unique identifier for the Consignee object",
    },
    {
      parameter: "consigneeName",
      type: "string",
      description: "Name of Consignee",
    },
    {
      parameter: "email",
      type: "string",
      description: "Email of Consignee",
    },
    {
      parameter: "address",
      type: "object",
      description: "Address object of Consignee",
    },
    {
      parameter: "address1",
      type: "string",
      description: "Address of Consignee",
    },
    {
      parameter: "city",
      type: "string",
      description: "City name of Consignee",
    },
    {
      parameter: "state",
      type: "string",
      description: "State name of Consignee",
    },
    {
      parameter: "zip",
      type: "string",
      description: "Zip code of Consignee",
    },
    {
      parameter: "country",
      type: "string",
      description: "Country name of Consignee",
    },
  ],
};

const jsonContent = `{
  "consigneeId": "PerfectChristmasTrees6310",
  "consigneeName": "Perfect Christmas Trees",
  "email": "californiabaca@gmail.com",
  "address": {
      "address1": "999 US-22",
      "city": "Phillipsburg",
      "state": "NJ",
      "zip": "",
      "country": "US"
  }`;

const Consignee = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};

export default Consignee;
