export const CustomIcon404_1 = ({ className }) => (
  <svg
    className={`cicon ${className}`}
    viewBox="0 0 817 252"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity=".5"
      d="M816.35 251.184H.373c22.053-19.765 54.285-39.53 91.607-54.353 20.357-7.412 44.106-17.294 67.856-22.235 71.25-19.765 157.767-32.118 249.374-32.118 110.267 0 212.052 17.294 290.087 44.471 27.143 9.882 50.893 19.764 71.25 32.117 18.66 9.883 33.928 19.765 45.803 32.118Z"
      fill="url(#a)"
    />
    <path
      opacity=".3"
      d="M171.133 186.547c1.069 10.035-16.418 22.046-36.661 24.202-20.242 2.156-38.002-4.409-40.757-14.264 19.708-7.174 42.611-16.379 65.871-22.239 7.104 2.626 11.012 7.284 11.547 12.301Z"
      fill="url(#b)"
    />
    <path
      opacity=".3"
      d="M314.2 179.478c27.17 0 49.196-5.271 49.196-11.773s-22.026-11.773-49.196-11.773c-27.17 0-49.196 5.271-49.196 11.773s22.026 11.773 49.196 11.773Z"
      fill="url(#c)"
    />
    <path
      opacity=".3"
      d="M579.383 178.513c.557-8.342-20.968-16.548-48.079-18.329-27.11-1.782-49.539 3.536-50.097 11.878-.558 8.342 20.968 16.548 48.078 18.329 27.111 1.782 49.54-3.537 50.098-11.878Z"
      fill="url(#d)"
    />
    <path
      opacity=".3"
      d="M766.157 216.412c-6.741-.777-14.836-.517-24.754-3.354-26.772-4.78-47.717-15.661-46.753-24.015.192-1.671.192-1.671 2.07-3.147 26.194 9.793 50.002 19.264 69.437 30.516Z"
      fill="url(#e)"
    />
    <path
      d="M292.16 116.05v33.638h-20.357v31.956h-39.017v-31.956h-84.821v-38.683L226 3.363h45.803V116.05h20.357Zm-57.678-68.957-49.196 68.957h49.196V47.093Z"
      fill="url(#f)"
    />
    <path
      d="M414.302 185.009c-40.714 0-76.339-35.32-76.339-92.505C337.963 35.32 373.588 0 414.302 0s76.339 35.32 76.339 92.504c1.696 55.503-33.929 92.505-76.339 92.505Zm0-35.32c18.66 0 39.017-18.501 39.017-58.867 0-38.683-18.66-58.866-39.017-58.866-18.661 0-39.018 18.5-39.018 58.866 1.697 40.366 20.357 58.867 39.018 58.867Z"
      fill="url(#g)"
    />
    <path
      d="M684.029 116.05v33.638h-20.357v31.956h-39.017v-31.956h-84.821v-38.683L617.869 3.363h44.107V116.05h22.053Zm-59.374-68.957-49.196 68.957h49.196V47.093Z"
      fill="url(#h)"
    />
    <path
      d="M276.881 116.051v33.637h-20.357v31.957h-39.018v-31.957h-84.82v-38.683L210.721 3.364h44.107V116.05h22.053Zm-57.678-68.958-49.196 68.958h49.196V47.093Z"
      fill="url(#i)"
    />
    <path
      d="M399.022 185.009c-40.714 0-76.338-35.32-76.338-92.505C322.684 35.32 358.308 0 399.022 0c40.714 0 76.339 35.32 76.339 92.504 1.697 55.503-33.928 92.505-76.339 92.505Zm0-35.32c18.661 0 39.018-18.501 39.018-58.866 0-38.684-18.661-58.867-39.018-58.867-18.66 0-39.017 18.501-39.017 58.867 1.696 40.365 20.357 58.866 39.017 58.866Z"
      fill="url(#j)"
    />
    <path
      d="M668.75 116.051v33.637h-20.357v31.957h-39.017v-31.957h-84.821v-38.683L602.59 3.364h44.107V116.05h22.053Zm-59.374-68.958-49.197 68.958h49.197V47.093Z"
      fill="url(#k)"
    />
    <defs>
      <linearGradient
        id="a"
        x1="408.362"
        y1="142.478"
        x2="408.362"
        y2="251.184"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6BFD7" />
        <stop offset="1" stopColor="#838DA7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="131.039"
        y1="178.462"
        x2="133.039"
        y2="197.236"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="314.208"
        y1="156.75"
        x2="314.208"
        y2="169.966"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="484.618"
        y1="172.284"
        x2="539.718"
        y2="175.967"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        id="e"
        x1="732.027"
        y1="190.758"
        x2="730.572"
        y2="203.367"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        id="f"
        x1="220.074"
        y1="9.557"
        x2="220.074"
        y2="109.624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D6DCE6" />
        <stop offset="1" stopColor="#C4CBDA" />
      </linearGradient>
      <linearGradient
        id="g"
        x1="414.343"
        y1="6.427"
        x2="414.343"
        y2="110.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D6DCE6" />
        <stop offset="1" stopColor="#C4CBDA" />
      </linearGradient>
      <linearGradient
        id="h"
        x1="611.943"
        y1="9.557"
        x2="611.943"
        y2="109.624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D6DCE6" />
        <stop offset="1" stopColor="#C4CBDA" />
      </linearGradient>
      <linearGradient
        id="i"
        x1="204.795"
        y1="9.557"
        x2="204.795"
        y2="109.624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAEEF5" />
        <stop offset="1" stopColor="#D4DAE4" />
      </linearGradient>
      <linearGradient
        id="j"
        x1="399.064"
        y1="6.427"
        x2="399.064"
        y2="110.271"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAEEF5" />
        <stop offset="1" stopColor="#D4DAE4" />
      </linearGradient>
      <linearGradient
        id="k"
        x1="596.664"
        y1="9.557"
        x2="596.664"
        y2="109.624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAEEF5" />
        <stop offset="1" stopColor="#D4DAE4" />
      </linearGradient>
    </defs>
  </svg>
);
