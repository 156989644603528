import React from 'react'
import DynamicComponent from "../../../../components/Common/DynamicComponent/DynamicComponent";

import retrieveContainerSizesData from './../constants/container/containerSizes.json' 
const data = {
  title: "Retrieve All Container Sizes",
  subTitle: "This endpoint retrieves all container sizes. You can use this to update relevant information in your system.",
  requestLink: "GET  /tenders/container-sizes",
  queryParameters: null,
  requestParameters: null,
  note: "When creating a tender or updating tender send the container Size name in the containerSize to assign containerSize to a Tender",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
};

const content = JSON.stringify(retrieveContainerSizesData,null,4)

const RetrieveContainerSizes = () => {
  return (
    <div>
      <DynamicComponent props={data} content={content}/>
    </div>
  )
}

export default RetrieveContainerSizes