import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Retrieve One Load",
  subTitle: "This endpoint will retrieve a specific load. ",
  requestLink: "GET  /loads/:referenceNumber",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
  paramsParameters : [
    {
        parameter:'referenceNumber',
        isRequired: true,
        type:'string',
        description:'Load Reference Number of load',
    },
]
};

const jsonContent = `{
  "_object": "/api/response",
  "self": "/v1/loads/M_123444",
  "version": "1.0.0",
  "data": <Load Object>,
  "error": null
}`;

const GetOneLoad = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  );
};

export default GetOneLoad;
