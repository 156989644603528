import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Retrieve Load Documents",
  subTitle: "This endpoint retrieve specific load documents.",
  description: "You'll retrieve only allowed documents by carrier and example of document object you can find here.",
  requestLink: "GET  /loads/get-documents",
  types: [
    "Bill of Lading",
    "Quote",
    "Proof of Delivery",
    "Delivery Order",
    "TIR",
    "Rate Confirmation",
    "TIR IN",
    "TIR OUT",
    "TIR Chassis",
    "Packing Slip",
    "Email",
    "Dock Receipt",
    "Scale Ticket",
    "635 Ingate Receipt",
    "635 Outgate Receipt",
    "Tolls",
    "Other",
    "CONTAINER / CHASSIS- IN - PICTURE",
    "CONTAINER / CHASSIS -OUT- PICTURE",
    "GENSET PICTURE IN - PICTURE OUT",
    "VOID OUT TICKET",
    "REVISED 1",
    "REVISED 2",
    "REVISED 3" ,
    'DEMURRAGE (RECEIPT)',
    'PIER PASS (RECEIPT)' ,
    'TEMPERATURE UNIT' ,
    'DAMAGED CARGO-BOX' ,
    'Haz Mat'  ,
    'Lumper Receipt' ,
    'Invoice' ,
    'Rate Con' ,
    'Detention PICs'  ,
    'Seal Import',
    'Seal Export'  ,
    'Seal Improper' ,
    'Debris Pics',
  ],
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
  queryParameters: [
    {
      parameter: "referenceNumber",
      type: "string",
      description: "Reference number of load",
      isRequired: true
    },
    {
      parameter: "docType",
      type: "string",
      description:
        "Type of the documents. One of: Available types",
    }
    ]
};

const jsonContent = `{
  "_object": "/api/response",
  "self": "/v1/loads/get-documents",
  "version": "1.0.0",
  "data": {
    "date": "2022-07-30T08:47:24.005Z",
    "url": "https://s3.amazonaws.com/abc.pdf",
    "type": "TIR IN",
    "checked": true
},
  "error": null
}`;

const GetLoadDocument = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  );
};

export default GetLoadDocument;
