import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Invoices",
  subTitle: "This endpoint will retrieve invoice related information.",
  queryParameters: [
    {
      parameter: "_id",
      type: "string",
      description: "Unique identifier for the object",
    },
    {
      parameter: "total",
      type: "int32",
      description: "Total No of Invoice",
    },
    {
      parameter: "invoice",
      type: "array",
      description: "Array of invoice item object",
    },
    {
      parameter: "load_reference_number",
      type: "string",
      description: "Reference number of load",
    },
    {
      parameter: "reference_number",
      type: "string",
      description: "Reference number of load",
    },
    {
      parameter: "status",
      type: "string",
      description: "Current status of invoice",
    },
    {
      parameter: "paidAmount",
      type: "int32",
      description: "Paid amount of invoice",
    },
    {
      parameter: "totalAmount",
      type: "int32",
      description: "Total amount of invoice",
    },
    {
      parameter: "remainAmount",
      type: "int32",
      description: "Remain amount of invoice",
    },
    {
      parameter: "billingDate",
      type: "date/string",
      description: "Billing date of invoice",
    },
    {
      parameter: "caller",
      type: "object",
      description: "Caller object which you can refer in embedded objects",
    },
    {
      parameter: "referenceFields",
      type: "object",
      description:
        "Object of all reference fields like Container no, Vessel_name and etc",
    },
    {
      parameter: "pricing",
      type: "array",
      description: "Array of pricing item object",
    },
  ],
  note: "Other parameters are explain in specific API references.",
};

const jsonContent = `{
    "_id": "POP2_M102782",
    "total": 1,
    "invoice": [
        {
            "load_reference_number": "POP2_M102782",
            "reference_number": "POP2_M102782",
            "pricing": [
                {
                    "chargeType": "DYNAMIC_PRICING",
                    "name": "Chassis",
                    "perType": "perday",
                    "freeDays": 0,
                    "unit": "1.00",
                    "finalAmount": "85.00",
                    "chargePerDay": 85,
                    "amount": "85.00"
                },
                {
                    "chargeType": "DYNAMIC_PRICING",
                    "name": "Chassis",
                    "perType": "perday",
                    "freeDays": 0,
                    "unit": "1.00",
                    "finalAmount": "150.00",
                    "chargePerDay": 150,
                    "amount": "150.00"
                }
            ],
            "status": "BILLING",
            "paidAmount": 0,
            "totalAmount": 1984,
            "remainAmount": 1984,
            "billingDate": "2022-07-21T06:41:35.861Z",
            "caller": {
                "company_name": "National Retail Store",
                "currency": ""
            },
            "callerName": "National Retail Store",
            "referenceFields": {
                "containerNo": "CCON123456",
                "purchaseOrderNo": "PurchaseOrder",
                "masterBillOfLading": "Master Bill of Lading",
                "pickUpNo": "Pick Up #",
                "vesselName": "Vessel Name",
                "houseBillOfLading": "House Bill of Lading",
                "voyage": "Voyage",
                "returnNo": "Return #",
                "secondaryReferenceNo": "Reference #",
                "sealNo": "Seal #",
                "shipmentNo": "Shipment #"
            }
        }
    ]
}`;

const Invoices = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};

export default Invoices;
