import React from 'react'

import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Authentication",
  description: "PortPro uses three keys.",
  orderList: [ 'Refresh Key', 'Access Key', 'Carrier Key'],
  description1: "These Keys allow access to the API. Please refer to the diagram below which illustrates the entire authentication flow.",
  description2: "PortPro uses different endpoints for authentication API’s. You can refer to the information below, URL & Endpoint.",
  image: require('../../../assets/img/Authentication.jpeg'),
  note: "PortPro Access Key and Carrier Key has 24 Hours Validity and Refresh key has 100 Days validity.",
  requestLink: process.env.REACT_APP_AUTHENTICATION_URL,
};


const Authentication = () => {
  return (
    <div>
      <DynamicComponent props={data} content='' />
    </div>
  )
}

export default Authentication
