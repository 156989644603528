import React from "react";
import { Accordion } from "../Accordation/Accordation";
import { routesData } from "./routesData";

export const SideBarLeft = ({ selected, setSelected }) => {
  return (
    <aside className="left-sidebar pt-10 pb-10 pl-10">
      <nav className="sidebar-nav">
        <div className="d-flex justify-content-between w-100 ">
          {/* <div className="mr-10 bg-white w-100 app-search header-search position-relative ">
            <span className="search-icon "></span>
            <div className="position-relative">
              <input
                type="search"
                className="form-control input-search-left"
                placeholder="Search..."
              />
            </div>
          </div> */}
        </div>
        {/* <Link className="sidebar-link">
          <i className="uil uil-map"></i>
          <span className="hide-menu">Tracking</span>
        </Link> */}
        <div className="menu-accordion mt-4 pr-10 mb-5">
          {routesData.map((route,index) => (
            <Accordion title={route.title} childrens={route.childrens} route={route.route} selected={selected} setSelected={setSelected} key={index}/>
          ))}

        </div>
      </nav>
    </aside>
  );
};
