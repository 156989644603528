import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
export const Accordion = ({
  title,
  childrens,
  setSelected,
  selected,
  route,
}) => {
  const [isOpen, setOpen] = useState(false);
  let match = useRouteMatch();
  return (
    <>
      <div className="accordion-wrapper m-0" onClick={() => setSelected(title)}>
        <Link
          to={`${match.url}/${route}`}
          replace={false}
        >
          <div
            className={`accordion-title text-uppercase ${
              selected === title ? "open" : ""
            }`}
            onClick={() => setOpen(!isOpen)}
          >
            {title}
          </div>
        </Link>
        <div
          className={`accordion-item ${
            selected !== title ? "collapsed" : ""
          } mt-1`}
        >
          <div className="accordion-content">
            {childrens.map((item, index) => (
              <Link to={`${match.url}/${route}/${item.route}`} replace={true} key={index}>
                <div className="accordation-child">
                  <span className="accordation-child__text d-block text-capitalize">
                    {item.title}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};