import React from "react";

import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Retrieve Refresh Token",
  subTitle: "This endpoint allows you to retrieve a refresh token directly when it expires.",
  requestLink: `POST ${process.env.REACT_APP_AUTHENTICATION_URL}/retrieve-refresh`,
  requestParameters: [
    {
      parameter: 'clientId',
      isRequired: true,
      type: 'string',
      description: 'Client ID of your developer portal account which is provided by support team.',
    },
    {
      parameter: 'clientSecret',
      isRequired: true,
      type: 'string',
      description: 'Client Secret of your developer portal account which is provided by support team.',
    },
    {
      parameter: "environment",
      type: "String['sandbox', 'production']",
      isRequired: true,
      description: "In which environment the token will be going to use",
    },
  ]
};

const jsonContent = `{

  Request body Sample JSON

  {
    "clientId": "1234567",
    "clientSecret": "abc12bcd34nmo",
    "environment": "production"
  }
  All paramerer is required.

  Response should be
  
  "_object": "/api/response",
  "self": "/v1/auth/retrieve-refresh",
  "version": "1.0.0",
  "data": {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXY1ODU2Mzc5OH0.Z9GGfzOpyqWTbSTIqFHsMJBu_nu3YDibwpt7ZLsjz5o"
  },
  "error": null
}
`;
const GetRefreshToken = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} isHeader={true} />
    </div>
  );
};

export default GetRefreshToken;
