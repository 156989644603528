import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export const NavbarMenu = ({ tab, setTab }) => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname === "/api-keys") {
      setTab(2)
    }
    else if (pathname === "/webhooks") {
      setTab(3)
    }
    else {
      setTab(1)
    }
  }, [pathname,setTab])

  return (
    <div className="subheader">
      <nav id="navbar-example2" className="navbar navbar-light bg-dark p-0">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link
              className={`nav-link ${tab === 1 ? "bg-blue-500" : ""}`}
              to="/documentation/introduction"
              onClick={() => setTab(1)}
            >
              Documentation
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${tab === 2 ? "bg-blue-500" : ""}`}
              to="/api-keys"
              onClick={() => setTab(2)}
            >
              API Keys
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${tab === 3 ? "bg-blue-500" : ""}`}
              to="/webhooks"
              onClick={() => setTab(3)}
            >
              Webhooks
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
