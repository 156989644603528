import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Carrier",
  subTitle: "Carrier object matrix",
  customName:" ",
  queryParameters: [
    {
      parameter: "USDOTNumber",
      type: "string",
      description: "USD ot Number",
    },
    {
      parameter: "mobile",
      type: "string",
      description: "Mobile number of Carrier",
    },
    {
      parameter: "carrierName",
      type: "string",
      description: "Name of Carrier",
    },
    {
      parameter: "homeTerminalTimezone",
      type: "string",
      description: "Timezone of home terminal",
    },
    {
      parameter: "distanceMeasure",
      type: "string",
      description: "Distance measurement for Carrier",
    },
    {
      parameter: "address",
      type: "object",
      description: "Address object of Carrier",
    },
    {
      parameter: "companyAddress",
      type: "string",
      description: "Address of Carrier",
    },
    
  ],
};

const jsonContent = `{
  "USDOTNumber": "23123121",
  "mobile": 5624725010,
  "carrierName": "Daniel Baca",
  "homeTerminalTimezone": "America/New_York",
  "distanceMeasure": "mi",
  "address": {
      "companyAddress": "1634 N Lewis Ave, Tulsa, OK 74110, USA"
  }`;

export const CarrierObj = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};