import React from 'react'
import DynamicComponent from '../../../components/Common/DynamicComponent/DynamicComponent';

const data = {
  title: "Introduction",
  description1: "Welcome to The PortPro Connect Developer Console!",
  description2: "With this documentation, console, and API, you'll be able to communicate straight with drayage carriers using drayOS - PortPro's drayage TMS. This PortPro API enables you to programmatically interact with PortPro's freight data. Once the integration is complete and the necessary components are in the solution, you'll be able to tender loads, pass through appointment information, and share other relevant information straight to drayOS. The API follow modern RESTful conventions and speaks JSON bidirectionally. As the API uses JSON for both requests and responses, we will assume that requests with a payload are using properly formatted JSON requests. We are recommending that you set the Content-Type header to the value applications/JSON.",
  description3: "In return, this integration will enable drayOS users to pass back information they feel is important to your relationship, inclusive of documentation like Proof of Delivery, status updates such as 'picked up' & 'dropped off', among other relevant metrics.",
  description4: "We're excited to partner with you and look forward to our continued relationship."
};

const jsonContent = '';

const Introduction = () => {
  return (
    <div><DynamicComponent onlyContent={true} props={data} content={jsonContent}/></div>
  )
}

export default Introduction