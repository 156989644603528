import React from 'react'
import DynamicComponent from '../../../components/Common/DynamicComponent/DynamicComponent';

const data = {
  title: "Generate Carrier Token",
  subTitle: "After getting approval from a PortPro admin, you can continue with the integration process.",
  requestLink: `GET ${process.env.REACT_APP_AUTHENTICATION_URL}/generate-carrier`,
  title2: "API Setup",
  requestLink2: "Authorization: <Your Refresh Token>",
  queryParameters: [
    {
      parameter: "environment",
      type: "String['sandbox','production']",
      description: "The environment by which the token will be used.",
      isRequired: true,
    },
    {
      parameter: "carrierID",
      type: "String",
      description: "The motor carrierID.",
      isRequired: true
    },
  ],
  note: "Here you receive a Carrier token in the “token” field. Carrier token has 24 Hrs of validity.",
};

const jsonContent = `{
  "_object": "/api/response",
  "self": "/v1/token/generate-carrier",
  "version": "1.0.0",
  "data": {
      "token": "eyJhbGciOiJIUzIaWQiOiI2MmQ4ZjhhYzZkNmE1OTIzZmE0NDBhMzMiLCJpYXQiOjE2NTg0NzczOTgsImV4cCI6MTY1ODU2Mzc5OH0.Z9GGfzOpyqWTbSTIqFHsMJBu_nu3YDibwpt7ZLsjz5o"
  },
  "error": null
}
`;
const GenerateCarrierToken = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  )
}

export default GenerateCarrierToken
