import React from 'react'
import DynamicComponent from "../../../../components/Common/DynamicComponent/DynamicComponent";
import retrieveConsigneeData from './../constants/customers/retrieveConsignee.json' 
const data = {
  title: "Retrieve All Consignees",
  subTitle: "This endpoint retrieve all consignees you can use to update tender.",
  requestLink: "GET  /tenders/consignee",
  queryParameters: null,
  requestParameters: null,
  note: "when creating a tender or updating tender send the consignee name in the consigneeValue",
  remember: "you can also send the consigneeAddressValue along with the consigneeValue if the consigneeNames are unique",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
};

const jsonContent = JSON.stringify(retrieveConsigneeData,null,4)

const RetrieveShipper = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  )
}

export default RetrieveShipper