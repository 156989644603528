import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
    title: "Appointments",
    subTitle: "Appointments object matrix",
    customName:" ",
    queryParameters: [
      {
        parameter: "pickupTimes",
        type: "object",
        description: "Pickup Times object of appointments",
      },
      {
        parameter: "pickupToTime",
        type: "	date/string",
        description: "Pickup To Time of Load Routing ",
      },
      {
        parameter: "pickupFromTime",
        type: "	date/string",
        description: "Pickup From Time of Load Routing",
      },
      {
        parameter: "deliveryTimes",
        type: "	object",
        description: "DeliveryTimes object of appointments",
      },
      {
        parameter: "deliveryToTime",
        type: "	date/string",
        description: "Delivery To Times of Load Routing",
      },
      {
        parameter: "deliveryFromTime",
        type: "	date/string",
        description: "Delivery From Times of Load Routing",
      },
      {
        parameter: "emptyOriginTimes",
        type: "object",
        description: "Empty Origin Times object of container return",
      },
      {
        parameter: "returnFromTime",
        type: "	date/string",
        description: "Return From Time Times of Load Routing",
      },
      {
        parameter: "returnToTime",
        type: "	date/string",
        description: "Return To Time of Load Routing",
      },
      {
        parameter: "emptyOriginInfo",
        type: "object",
        description: "Empty Origin Info object of Container",
      },
      {
        parameter: "userName",
        type: "string",
        description: "user name of Container",
      },
      {
        parameter: "address",
        type: "object",
        description: "Address object of Empty Origin",
      },
      {
        parameter: "address1",
        type: "string",
        description: "Address1 of Empty Origin",
      },
      {
        parameter: "city",
        type: "string",
        description: "City name of Empty Origin",
      },
      {
        parameter: "state",
        type: "string",
        description: "State name of Empty Origin",
      },
      {
        parameter: "zip",
        type: "string",
        description: "Zip code of Empty Origin",
      },
      {
        parameter: "country",
        type: "string",
        description: "Country name of Empty Origin",
      },
    ],
  };
const jsonContent = ` {
    "pickupTimes": {
        "pickupToTime": "2022-07-01T04:00:00.000Z",
        "pickupFromTime": "2022-07-01T04:00:00.000Z"
    },
    "deliveryTimes": {
        "deliveryToTime": "2022-07-02T04:00:00.000Z",
        "deliveryFromTime": "2022-07-02T04:00:00.000Z"
    },
    "emptyOriginTimes": {
        "returnFromTime": "2022-07-06T04:00:00.000Z",
        "returnToTime": "2022-07-07T04:00:00.000Z",
        "emptyOriginInfo": {
            "userName": "GCT BAYONNE",
            "address": {
                "address1": "302 Port Jersey Blvd",
                "city": "Jersey City",
                "state": "NJ",
                "zip": "07305",
                "country": "US"
            }
        }
    }
}`;

export const Appointments = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};
