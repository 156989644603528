import React from 'react'

import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Carrier",
  description1: "The Drayage Carriers are PortPro customers. In this module, you can access your carrier list using the following API.",
};

const Carrier = () => {
  return (
    <div>
      <DynamicComponent props={data} content='' />
    </div>
  )
}

export default Carrier
