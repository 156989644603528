import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
    title: "Events",
    subTitle: "Events object matrix",
    customName:" ",
    queryParameters: [
      {
        parameter: "departedTime",
        type: "date/string",
        description: "departed Time of Load",
      },
      {
        parameter: "arrivedTime",
        type: "date/string",
        description: "arrived Time of Load ",
      },
      {
        parameter: "isVoidOut",
        type: "boolean",
        description: "Is driver void out or not ",
      },
      {
        parameter: "type",
        type: "string",
        description: "Current type of Load routing ",
      },
      {
        parameter: "companyName",
        type: "string",
        description: "Name of company",
      },
    ],
  };
const jsonContent = ` {
    "departedTime": "2022-07-22T06:55:04.154Z",
    "arrivedTime": "2022-07-22T06:55:04.154Z",
    "isVoidOut": false,
    "type": "PULLCONTAINER",
    "companyName": "GCT BAYONNE"
},`;

export const Event = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};
