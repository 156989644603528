export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";

export const WEBHOOK_TOKEN_REQUEST = "WEBHOOK_TOKEN_REQUEST";
export const WEBHOOK_TOKEN_SUCCESS = "WEBHOOK_TOKEN_SUCCESS";
export const WEBHOOK_TOKEN_FAIL = "WEBHOOK_TOKEN_FAIL";

export const WEBHOOK_REQUEST = "WEBHOOK_REQUEST";
export const WEBHOOK_SUCCESS = "WEBHOOK_SUCCESS";
export const WEBHOOK_FAIL = "WEBHOOK_FAIL";
export const UPDATE_WEBHOOK_RESET = "UPDATE_WEBHOOK_RESET";

export const GET_WEBHOOK_REQUEST = "GET_WEBHOOK_REQUEST";
export const GET_WEBHOOK_SUCCESS = "GET_WEBHOOK_SUCCESS";
export const GET_WEBHOOK_FAIL = "GET_WEBHOOK_FAIL";

export const TOKEN_CLEAR = "TOKEN_CLEAR";
export const WEBHOOK_TOKEN_CLEAR = "WEBHOOK_TOKEN_CLEAR";

export const GET_WEBHOOK_HISTORY_REQUEST = "GET_WEBHOOK_HISTORY_REQUEST"
export const GET_WEBHOOK_HISTORY_SUCCESS = "GET_WEBHOOK_HISTORY_SUCCESS"
export const GET_WEBHOOK_HISTORY_FAIL = "GET_WEBHOOK_HISTORY_FAIL"