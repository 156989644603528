import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Retrieve All Loads",
  subTitle: "This endpoint retrieve all load.",
  requestLink: "GET  /loads/",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
  queryParameters: [
    {
      parameter: "searchTerm",
      type: "String",
      description: "Search term for load"
    },
    {
      parameter: "typeOfLoad",
      type: "Array[string]",
      description: "Define type of load"
    },
    {
      parameter: "referenceNumbers",
      type: "Array[string]",
      description: "Array of reference numbers."
    },
    {
      parameter: "skip",
      type: "Int[int32]",
      description: "Skip loads count"
    },
    {
      parameter: "limit",
      type: "Int[int32]",
      description: "Limit on loads count page"
    },
    {
      parameter: "isSubCustomer",
      type: "Boolean",
      description: "For get sub customers loads."
    },
    {
      parameter: "deliveryFrom",
      type: "Date/string",
      description: "Delivery from date of load for filter."
    },
    {
      parameter: "deliveryTo",
      type: "Date/string",
      description: "Delivery to date of load for filter."
    },
    {
      parameter: "containerNo",
      type: "String",
      description: "Container number of load for filter."
    },
    {
      parameter: "billingFrom",
      type: "String",
      description: "Billing from date of load for filter."
    },{
      parameter: "billingTo",
      type: "String",
      description: "Billing to date of load for filter."
    },
  ]
};

const jsonContent = `{
  "_object": "/api/response",
  "self": "/v1/loads",
  "version": "1.0.0",
  "data": [<Load Object>, <Load Object>],
  "error": null
}`;

const GetAllLoads = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  );
};

export default GetAllLoads;
