import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Loads",
  subTitle: "This endpoint will retrieve load related information.",
  statusHeading: "Load status details.",
  statusDetails: [
    {
      name: "Pending",
      description: "The Load is Pending.",
    },
    {
      name: "Available",
      description: "The Load is Available.",
    },
    {
      name: "Dispatched",
      description: "The Load is Dispatched.",
    },
    {
      name: "Enroute to Chassis",
      description: "Enroute to pick up the Chassis.",
    },
    {
      name: "Arrived to Chassis",
      description: "Arrived at pick up the Chassis.",
    },
    {
      name: "Enroute to Pick Container",
      description: "Enroute to Pick up the Container.",
    },
    {
      name: "Arrived at Pick Container",
      description: "Arrived at pick up the Container.",
    },
    {
      name: "Enroute to Drop Container",
      description: "Enroute to Drop the Container.",
    },
    {
      name: "Dropped",
      description: "The container is Dropped.",
    },    {
      name: "Dropped-Empty",
      description: "Dropped container is empty.",
    },
    {
      name: "Dropped-Loaded",
      description: "Dropped container is loaded.",
    },
    {
      name: "Enroute to Hook Container",
      description: "Enroute to Hook the Container.",
    },
    {
      name: "Arrived to Hook Container",
      description: "Arrived at Hook the Container.",
    },
    {
      name: "Enroute to Deliver Load",
      description: "Enroute to Deliver the Load.",
    },
    {
      name: "Arrived at Deliver Load",
      description: "Arrived at  Deliver Load.",
    },
    {
      name: "Enroute to Return Empty",
      description: "Enroute to Return the Empty Container.",
    },
    {
      name: "Arrived at Return Empty",
      description: "Arrived at Return the Empty Container.",
    },
    {
      name: "Enroute to Return Chassis",
      description: "Enroute to Return the Chassis.",
    },
    {
      name: "Arrived to Return Chassis",
      description: "Arrived at Return the Chassis.",
    },
    {
      name: "Completed",
      description: "The load is Completed.",
    },
    {
      name: "Billing",
      description: "The load is in Billing Status.",
    },
  ],
  queryParameters: [
    {
      parameter: "referenceNumber",
      type: "string",
      description: "Reference number of load",
    },
    {
      parameter: "typeOfLoad",
      type: "string",
      description:
        "Type of the load. One of: [IMPORT, EXPORT, ROAD, BILL_ONLY]",
    },
    {
      parameter: "status",
      type: "string",
      description: "Current status of load",
    },
    {
      parameter: "distance",
      type: "int32",
      description: "Distance of load",
    },
    {
      parameter: "carrierHold",
      type: "boolean",
      description: "Is Carrier hold or not",
    },
    {
      parameter: "brokerHold",
      type: "boolean",
      description: "Is broker or not",
    },
    {
      parameter: "overWeight",
      type: "boolean",
      description: "Is over weight or not",
    },
    {
      parameter: "isLoadDropAtPull",
      type: "boolean",
      description: "Is load drop at pull or not",
    },
    {
      parameter: "isLoadDropAtYard",
      type: "boolean",
      description: "Is load drop at yard or not",
    },
    {
      parameter: "isHot",
      type: "boolean",
      description: "Is hot or not",
    },
    {
      parameter: "isNextContainerNeedToPickup",
      type: "boolean",
      description: "Is next container need to pickup or not",
    },
    {
      parameter: "hot",
      type: "boolean",
      description: "Is hot or not",
    },
    {
      parameter: "hazmat",
      type: "boolean",
      description: "Is hazmat or not",
    },
    {
      parameter: "totalMiles",
      type: "int32",
      description:"",
    },
    {
      parameter: "container",
      type: "object",
      description: "Container object which you can refer in embedded objects",
    },
    {
      parameter: "caller",
      type: "object",
      description: "Caller object which you can refer in embedded objects",
    },
    {
      parameter: "carrier",
      type: "object",
      description:
        "Carrier object which you can refer in embedded objects",
    },
    {
      parameter: "shipper",
      type: "object",
      description: "Shipper object which you can refer in embedded objects",
    },
    {
      parameter: "consignee",
      type: "string",
      description:
        "Type of the load. One of: [IMPORT, EXPORT, ROAD, BILL_ONLY]",
    },
    {
      parameter: "documents",
      type: "array",
      description: "Array of documents of load",
    },
    {
      parameter: "appointments",
      type: "object",
      description: "Appointments object which you can refer in embedded objects",
    },
    {
      parameter: "pricing",
      type: "array",
      description:
        "Array of pricing line items of load ",
    },
    {
      parameter: "expense",
      type: "array",
      description: "Array of expense of load ",
    },
    {
      parameter: "items",
      type: "array",
      description:
        "Array of items of load ",
    },
    {
      parameter: "events",
      type: "array",
      description: "Array of events of load ",
    },
    {
      parameter: "dates",
      type: "object",
      description: "Object of dates of load ",
    },
  ],
  note: "Other parameters are explain in specific API references.",
};

const jsonContent = `{
    "referenceNumber": "POP2_M10791",
    "typeOfLoad": "IMPORT",
    "status": "COMPLETED",
    "distance": 63.71,
    "carrierHold": false,
    "brokerHold": false,
    "overWeight": false,
    "isLoadDropAtPull": false,
    "isLoadDropAtYard": false,
    "isHot": false,
    "isNextContainerNeedToPickup": false,
    "hot": false,
    "hazmat": false,
    "totalMiles": 63.71,
    "container": {
        "isNextContainerNeedToPickup": false
    },
    "referenceNumbers": {
      "referenceNumber": "NOCM100771",
      "bookingNumber": "GBPE100773",
      "callerbillLandingNo": "NOCM100701",
      "appointmentNo": "POKL100985",
      "reservationNo": "POKL100005",
      "callerPONo": "POKL100523",
      "secondaryReferenceNo": "CWRE100772",
      "pickupNo": "GMPE100773",
      "releaseNo": "BGRE100295",
      "sealNo": "CURE100772",
      "shipmentNo": "BLRE100523",
      "houseBillOfLading": "NOCL100070",
      "purchaseOrderNo": "BLRE100290",
      "returnNo": "POKL100085",
      "deliveryOrderNo": "BGRE100222"
    },
    "dates": {
      "vesselDate": "2023-07-01T04:00:00.000+00:00Z",
      "lastFreeDay": "2023-07-02T04:00:00.000+00:00Z",
      "dischargedDate": "2023-07-03T06:45:00.000+00:00Z",
      "outgateDate": "2023-07-04T09:00:00.000+00:00Z",
      "emptyDay": "2023-07-05T04:00:00.000+00:00Z",
      "returnDay": "2023-07-08T15:53:00.000+00:00Z",
      "ingateDate": "2023-07-07T09:30:00.000+00:00Z",
      "readyToReturnDate": "2023-07-08T15:53:00.000+00:00Z",
      "loadTime": "2023-07-09T04:00:00.000+00:00Z",
      "ERD": "2023-07-31T04:00:00.000+00:00Z",
      "cutOff": "2023-07-12T08:30:00.000Z",
      "returnDate": "2023-07-28T05:00:00.000Z"
    }
    "caller": {
        "callerId": "dpsunday8425",
        "callerName": "dpsunday",
        "email": "jim5n0cj06ja4mo@portpro.io",
        "isEmailNeedToSend": true,
        "address": {
            "address1": "8201 Vineland Ave Suite 1801",
            "city": "Orlando",
            "state": "FL",
            "zip": "32821",
            "country": "US"
        }
    },
    "carrier": {
        "USDOTNumber": "23123121",
        "mobile": 5624725010,
        "carrierName": "Daniel Baca",
        "homeTerminalTimezone": "America/New_York",
        "distanceMeasure": "mi",
        "address": {
            "companyAddress": "1634 N Lewis Ave, Tulsa, OK 74110, USA"
        }
    },
    "shipper": {
        "shipperId": "GCTBAYONNE9648",
        "shipperName": "GCT BAYONNE",
        "email": "r56lg6hw96h678r@portpro.io",
        "address": {
            "address1": "302 Port Jersey Blvd",
            "city": "Jersey City",
            "state": "NJ",
            "zip": "",
            "country": "US"
        }
    },
    "consignee": {
        "consigneeId": "PerfectChristmasTrees6310",
        "consigneeName": "Perfect Christmas Trees",
        "email": "californiabaca@gmail.com",
        "address": {
            "address1": "999 US-22",
            "city": "Phillipsburg",
            "state": "NJ",
            "zip": "",
            "country": "US"
        }
    },
    "appointments": {
      "pickupTimes": {
          "pickupToTime": "2022-07-01T04:00:00.000Z",
          "pickupFromTime": "2022-07-01T04:00:00.000Z"
      },
      "deliveryTimes": {
          "deliveryToTime": "2022-07-02T04:00:00.000Z",
          "deliveryFromTime": "2022-07-02T04:00:00.000Z"
      },
      "emptyOriginTimes": {
          "returnFromTime": "2022-07-06T04:00:00.000Z",
          "returnToTime": "2022-07-07T04:00:00.000Z",
          "emptyOriginInfo": {
              "userName": "GCT BAYONNE",
              "address": {
                  "address1": "302 Port Jersey Blvd",
                  "city": "Jersey City",
                  "state": "NJ",
                  "zip": "07305",
                  "country": "US"
              }
          }
      }
    },
    "pricing": [
        {
            "name": "Chassis",
            "perType": "perday",
            "chargePerDay": 150,
            "amount": "150.00",
            "unit": 1,
            "finalAmount": "150.00",
            "chargeType": "DYNAMIC_PRICING"
        },
        {
            "name": "Base Price",
            "perType": "fixed",
            "chargePerDay": 1599,
            "amount": "1599.00",
            "unit": 1,
            "finalAmount": "1599.00",
            "chargeType": "DYNAMIC_PRICING"
        }
    ],
    "expenses": [
        {
            "name": "Base Price",
            "perType": "fixed",
            "amount": "0.00",
            "unit": 1,
            "finalAmount": "4.00"
        },
        {
            "name": "Base Price",
            "perType": "perday",
            "amount": "0.00",
            "finalAmount": "5.00"
        }
    ],
    "items": [
      {
          "commodity": "Perishables",
          "description": "abc",
          "pieces": 50,
          "weight": 500,
          "weightKgs": 226.8,
          "pallets": 50
      }
    ],
    "events": [
        {
            "departedTime": "2022-07-22T06:55:04.154Z",
            "arrivedTime": "2022-07-22T06:55:04.154Z",
            "isVoidOut": false,
            "type": "PULLCONTAINER",
            "companyName": "GCT BAYONNE"
        },
        {
            "departedTime": "2022-07-22T06:55:04.154Z",
            "arrivedTime": "2022-07-22T06:55:04.154Z",
            "isVoidOut": false,
            "type": "DELIVERLOAD",
            "companyName": "PERFECT CHRISTMAS TREES"
        },
        {
            "departedTime": "2022-07-22T06:55:04.154Z",
            "arrivedTime": "2022-07-22T06:55:04.154Z",
            "isVoidOut": false,
            "type": "RETURNCONTAINER"
        }
    ]
}`;

const Loads = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};

export default Loads;
