import React from 'react'
import NoteComponent from '../../../components/Common/DynamicComponent/components/NoteComponent'
const environmentVariable = [
    {
        name: "url",
        description: "production or sandbox endpoint."     
    },
    {
        name: "auth_url",
        description: "This endpoint you can used for authentication related APIs."     
    },
    {
        name: "refresh_token",
        description: "API keys which you can found in web application and this token will used for generate access key and carrier key."     
    },
    {
        name: "access_token",
        description: "Access key is used for authentication for some of apis and access key you can found from /token/generate-access endpoint."     
    },
    {
        name: "carrier_token",
        description: "This keys is used for trigger carrier specific apis."     
    },
    {
        name: "environment",
        description: "Here you can mention in which environment you're working on."     
    }
]

const SetupAPIs = () => {
  return (
    <div className="page-fluid">
      <div className="row no-gutters">
        <div className="row-lg-12 p-20">
        <div className="bg-white">
          <section>
            <h2 className='border-top border-bottom p-15'>Setup APIs into Postman</h2>
            <p className="font-regular font-14 text-black-600 p-15">
            Besides the sandbox environment tied to your developer account, you can use Postman to test REST API production code and endpoints.
            </p>
            <p className="font-regular font-14 text-black-600 p-15">
            Already using Postman? Simply download a <a href='/Customer-Developer-API.postman_collection.json' download='Portpro-customer-public-api.json' ><b>PortPro Customer APIs collection</b></a> and set up your testing <a href='/Customer-public-api-environment.json' download='Portpro-customer-public-api-environment.json'>environment</a>. If you need testing tools, Postman may be a great option - and we’ve got everything you need to get up and running.
            </p>
          </section>
          <hr/>
          <section className='create-tender-qs border-top border-bottom p-15'>
            <h2>Steps for Setup </h2>
            <p className="font-regular font-14 text-gray-600">
            Please follow the steps below to being setting up API's in Postman.
            </p>
            <hr/>
            <h3>Step 1: Set up your app in the Developer Portal</h3>
            <p className="font-regular font-14 text-gray-600 my-2">If you haven’t already, sign in to your developer account and <a href='https://developer.portpro.io/' target="_blank">create your app.</a></p>
            <hr/>
            <h3>Step 2: Get Postman</h3>
            <p className="font-regular font-14 text-gray-600 my-2"><a href='https://www.postman.com/' target="_blank">Download</a> and install Postman.</p>
            <hr/>
            <h3>Step 3: Get a Postman collection</h3>
            <p className="font-regular font-14 text-gray-600 my-2"><a href='/Customer-Developer-API.postman_collection.json' download='Portpro-customer-public-api.json'>Download</a> the Postman collections. Collections give you sets of pre-built requests to test with.</p>
            <hr/>
            <h3>Step 4: Configure your testing environment in Postman</h3>
            <p className="font-regular font-14 text-gray-600 my-2"><a href='/Customer-public-api-environment.json' download='Portpro-customer-public-api-environment.json'>Click here</a> for download environment postman collection.</p>
            <hr/>
            <div className='my-2'>
                  <h6 className="font-medium font-16">Enter the following in each field as needed:</h6>
                  <table>
                    <tr>
                      <th>Data field</th>
                      <th>Description</th>
                    </tr>
                    {environmentVariable.map((item) => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                  </table>
                </div>
            <ol className="m-3 font-regular font-14 text-gray-600">
                    <li className="font-regular font-14 text-gray-600 my-1">In Postman, go to the Environments Menu.</li>
                    <li className="font-regular font-14 text-gray-600 my-1">Select an environment template.</li>
                    <li className="font-regular font-14 text-gray-600 my-1">Replace URL and token as per your environment in environment variable.</li>
                    <li className="font-regular font-14 text-gray-600 my-1">At this point, everything is ready to begin testing. You should have an authorized token before you begin the set up process.</li>
            </ol>
            <hr/>
          <NoteComponent note="Here make sure about environment is placed right and use auth_url for authentication related apis."/>
          </section>
        </div>
        </div>
      </div>
    </div>
  )
}

export default SetupAPIs