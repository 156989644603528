import React from 'react'

const NoteComponent = ({note}) => {
  return (
  note ? <div className="bg-light px-20 py-15 mt-20 mb-2">
    <i className="uil-info-circle font-16 mr-1"></i> <span>{note}</span>
  </div>
  :null)
}

export default NoteComponent