import React from 'react'
import DynamicComponent from '../../../components/Common/DynamicComponent/DynamicComponent';

const ApiEnvironment = () => {
  const data = {
    title: "API Environments",
    description: "For now, we’ll provide two environments for your convenience.",
    orderList: [ 'Sandbox', 'Production']
  };

  const data1 = {
    title: "Sandbox",
    description1: "The Sandbox will allow you to simulate concurrent testing and development to fast-track app development cycles and reduce time-to-market. simulate error scenarios with your API, like latency in the API's response time, error conditions or simulating a non-responsive API completely.",
    requestLink: process.env.REACT_APP_SANDBOX_URL
  };

  const data2 = {
    title: "Production",
    description1: "The PortPro Production APIs provides standards for how software programs communicate with each other. PortPro API's enable you to programmatically interact with PortPro's freight data.",
    requestLink: process.env.REACT_APP_PRODUCTION_URL
  };
  
  const jsonContent = '';
    return (
      <div>
        <DynamicComponent onlyContent={true} props={data} content={jsonContent}/>
        <DynamicComponent noCodebase={true} onlyContent={true} props={data1} content={jsonContent}/>
        <DynamicComponent noCodebase={true} onlyContent={true} props={data2} content={jsonContent}/>
      </div>
    )
  }

export default ApiEnvironment