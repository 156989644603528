import axios from "axios";
import { GET_WEBHOOK_FAIL, GET_WEBHOOK_REQUEST, GET_WEBHOOK_SUCCESS, REFRESH_TOKEN_FAIL, REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, WEBHOOK_FAIL, WEBHOOK_REQUEST, WEBHOOK_SUCCESS, WEBHOOK_TOKEN_FAIL, WEBHOOK_TOKEN_REQUEST, WEBHOOK_TOKEN_SUCCESS, GET_WEBHOOK_HISTORY_REQUEST, GET_WEBHOOK_HISTORY_SUCCESS, GET_WEBHOOK_HISTORY_FAIL } from "../constants/apiKeyConstants";

export const getRefreshToken = (token, environment) => async (dispatch) => {
    try {
        dispatch({ type: REFRESH_TOKEN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
            }
        }
        const query = {
            url: `${process.env.REACT_APP_URL}/token/refresh-token?environment=${environment}`,
            method: "get",
            headers: config.headers,
        }
        const { data } = await axios(query);
        dispatch({ type: REFRESH_TOKEN_SUCCESS, payload: data.data.token, isExpired: data.data.isExpired })
    } catch ({ ...err }) {
        if(err.response.status === 401) {
            dispatch({ type: "USER_LOGOUT", error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
        }
         else {
            dispatch({ type: REFRESH_TOKEN_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
         }
    }
}


export const generateRefreshToken = (token, environment) => async (dispatch) => {
    try {
        dispatch({ type: REFRESH_TOKEN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
            }
        }
        const query = {
            url: `${process.env.REACT_APP_AUTH_URL}/generate-refresh?environment=${environment}`,
            method: "get",
            headers: config.headers,
        }
        const { data } = await axios(query);
        dispatch({ type: REFRESH_TOKEN_SUCCESS, payload: data.data.token })
    } catch ({ ...err }) {
        dispatch({ type: REFRESH_TOKEN_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
    }
}

export const getWebhookToken = (token) => async (dispatch) => {
    try {
        dispatch({ type: WEBHOOK_TOKEN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
            }
        }
        const query = {
            url: `${process.env.REACT_APP_URL}/webhook/token`,
            method: "get",
            headers: config.headers,
        }
        const { data } = await axios(query);
        dispatch({ type: WEBHOOK_TOKEN_SUCCESS, payload: data?.data?.token })
    } catch ({ ...err }) {
        if(err.response.status === 401) {
            dispatch({ type: "USER_LOGOUT", error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
        }
         else {
        dispatch({ type: WEBHOOK_TOKEN_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
         }
    }
}

export const generateWebhookToken = (token) => async (dispatch) => {
    try {
        dispatch({ type: WEBHOOK_TOKEN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
            }
        }
        const query = {
            url: `${process.env.REACT_APP_URL}/webhook/generate-token`,
            method: "get",
            headers: config.headers,
        }
        const { data } = await axios(query);
        dispatch({ type: WEBHOOK_TOKEN_SUCCESS, payload: data?.data?.token })
    } catch ({ ...err }) {
        dispatch({ type: WEBHOOK_TOKEN_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
    }
}

export const createOrUpdateWebhook = (token, datas) => async (dispatch) => {
    try {
        dispatch({ type: WEBHOOK_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
            }
        }
        const query = {
            url: `${process.env.REACT_APP_URL}/webhook/details`,
            method: "put",
            headers: config.headers,
            data: datas
        }
        const { data } = await axios(query);
        dispatch({ type: WEBHOOK_SUCCESS, eventType: data?.data?.eventType, webhookUrl: data?.data?.webhookUrl })
    } catch ({ ...err }) {
        dispatch({ type: WEBHOOK_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
    }
}

export const getWebhookDetails = (token) => async (dispatch) => {
    try {
        dispatch({ type: GET_WEBHOOK_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
            }
        }
        const query = {
            url: `${process.env.REACT_APP_URL}/webhook/details`,
            method: "get",
            headers: config.headers,
        }
        const { data } = await axios(query);
        dispatch({ type: GET_WEBHOOK_SUCCESS, eventType: data?.data?.eventType, webhookUrl: data?.data?.webhookUrl })
    } catch ({ ...err }) {
        dispatch({ type: GET_WEBHOOK_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
    }
}

export const getWebhookHistory=(token,{_id,limit,skip})=>async(dispatch)=>{
    try{
        dispatch({ type: GET_WEBHOOK_HISTORY_REQUEST });
        const config={
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
            }
        }

        const query = {
            url: `${process.env.REACT_APP_URL}/webhook/occured-customer-webhooks${limit?"?limit="+limit:""}${skip?"?skip="+skip:""}`,
            method: "get",
            headers: config.headers,
        }

        const {data}=await axios(query);
        dispatch({ type: GET_WEBHOOK_HISTORY_SUCCESS, payload:data?.data})
        } catch ({ ...err }) {
            dispatch({ type: GET_WEBHOOK_HISTORY_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
        }
   
}