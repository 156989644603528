import React from "react";
import { Link } from "react-router-dom";
import { CustomIcon404_1 } from "../../assets/customIcons/icons";

const NoMatch = () => {
  return (
    <div className="position-relative" style={{height: "100vh"}}>
      <div className="page-content container-fluid d-flex align-items-center h-100 justify-content-center">
        <div className="text-center">
          <CustomIcon404_1 className="errorpage-image" />
          <h4 className="font-20 font-medium mt-50 mb-10">
            Sorry, page not found
          </h4>
          <div className="text-muted mb-50">
            This page is not available now.{" "}
          </div>
          <Link className="btn btn-primary btn-lg" to="/">
            Go to Home Page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
