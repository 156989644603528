import React from 'react'
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";
import retrieveTender from './constants/retrieveTender.json';

const data = {
    title: "Retrieve Tender",
    subTitle: "This endpoint retrieve a Tender.",
    requestLink: "GET   /tenders/{{tenderReferenceNumber}}",
    title2: "API Setup",
    requestLink2: "Authorization: Bearer <Your Carrier Token>",
    paramsParameters: [
      {
        parameter: "tenderReferenceNumber",
        type: "string",
        description: "TenderReferenceNumber of Tender",
        isRequired: true
      },
    ],
    note: "Use tenderReferenceNumber of the tender load to retrieve the tender information",
    // remember: "",
  };

  const dataParam = {
    ...data,
    title: "Retrieve a Tender using param",
    requestLink: "GET /tenders/{{tenderReferenceNumber}}",
    paramsParameters: [
        {
          parameter: "/:tenderReferenceNumber",
          type: "string",
          description: "TenderReferenceNumber of Tender"
        }
    ],
    queryParameters:null,
    requestParameters:null,
 }
  
  const jsonContent = JSON.stringify(retrieveTender,null,4)

const RetrieveTender = () => {
  return (
    <>
        <DynamicComponent props={data} content={jsonContent}/>
        <hr/>
        <DynamicComponent props={dataParam} content = {jsonContent}/>
    </>
  )
}

export default RetrieveTender