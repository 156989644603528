import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Errors",
  subTitle: "The PortPro API uses the following error codes:",
  errorCodes: [
    {
      code: "400",
      description: "Bad Request -- Your request is invalid.",
    },
    {
      code: "401",
      description: "Unauthorized -- Your Access Token is wrong.",
    },
    {
      code: "403",
      description:
        "Forbidden -- The API requested is hidden for administrators only.",
    },
    {
      code: "404",
      description: "Not Found -- The specified API could not be found.",
    },
    {
      code: "405",
      description:
        "Method Not Allowed -- You tried to access a API with an invalid method.",
    },
    {
      code: "406",
      description: "Not Acceptable -- You requested a format that isn't json.",
    },
    {
      code: "410",
      description: "Gone -- The request has been removed from our servers.",
    },
    {
      code: "429",
      description:
        "Too Many Requests -- You're requesting too many API! Slow down!",
    },
    {
      code: "500",
      description:
        "Internal Server Error -- We had a problem with our server. Try again later.",
    },
    {
      code: "502/503",
      description:
        "Service Unavailable -- We're temporarily offline for maintenance. Please try again later.",
    },
  ],
};

const jsonContent = ``;

const Errors = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};

export default Errors;
