import React from 'react'
import NoteComponent from '../../../components/Common/DynamicComponent/components/NoteComponent'
import tenderFlowChart from '../../../assets/img/tender/tenderFlowChart.png'
import { Image } from 'react-bootstrap'
import {TENDER_CONSTANTS} from './constants/tender/tender.constants'
const sandBoxUrl = `${process.env.API_URL || 'https://sandbox.myapps.portpro.io'}`
// import tenderFlowChart.png from assets
const QuickStartTender = () => {
  return (
    <div className="page-fluid">
      <div className="row no-gutters">
        <div className="row-lg-12 p-20">
        <div className="bg-white">
          <section>
            <h2 className='border-top border-bottom p-15'>Introduction</h2>
            <p className="font-regular font-14 text-gray-600 p-15">
            To get started with Tenders, use the Tender API as a RESTful API. This allows you to create and update Tenders which can either be accepted or rejected by the Motor Carrier Company.
            </p>
            <Image src={tenderFlowChart} alt="tenderFlowChart" />
          </section>
          <hr/>
          <section className='create-tender-qs border-top border-bottom p-15'>
            <h2>Creating a tender </h2>
            <p className="font-regular font-14 text-gray-600">
              When creating A tender following payload in the request body is mandatory.
            </p>
            <hr/>
            <ul class="list-group">
              <li class="list-group-item list-group-item-primary">typeOfLoad</li>
              <li class="list-group-item">typeOfLoad can be IMPORT EXPORT or ROAD</li>
            </ul>
            <hr />
            <ul className="list-group">
              <li className="list-group-item list-group-item-primary">preSetOrderStatus</li>
              <li className="list-group-item">preSetOrderStatus can be one of {TENDER_CONSTANTS.preSetOrderStatus.join(', ')}</li>
            </ul>
            <hr/>
            <ul class="list-group">
              <li class="list-group-item list-group-item-primary">price or pricing</li>
              <li class="list-group-item">When price is used then the price will be created as a line item with Default Base Price Charge Code.</li>
              <li class="list-group-item">When pricing is used and price is auto-calculated.</li>
              <li class="list-group-item">price can only be used only at the time of tender creation.</li>
              <li class="list-group-item">pricing can be used in both cases while creating as well as updating a tender.</li>

            </ul>
            <NoteComponent note="To Create pricing line items either price or pricing can be used." />
          </section>
          <hr/>
          <section className='accept-tender-qs border-top border-bottom p-15'>
            <h2>Accepting a tender </h2>
            {/* <p className="font-regular font-14 text-gray-600">
              To accept a Tender the carrier 
              sandbox request URL is <span className="font-regular font-12 letter-spacing-1 text-gray-bold bg-light p-1 d-inline-block">{`/tenders/accept-reject/{{tenderReferenceNumber}}`}</span>
            </p> */}
            <p className="font-regular font-14 text-gray-600">
              Once a Tender is Accepted by the Motor Carrier Company the Tender will be created as a Load in DRAYOS from the online tendered loads under Customer Service.
            </p>
            <NoteComponent note="To Use the Tender API you need to have the access Token for the Motor Carrier. You can get one by Requesting the Motor Carrier" />
            <NoteComponent note="Nevertheless For testing you can use the carrier access token for accepting a tender." />
            <NoteComponent note="A Tender can only be accepted if the tender has consignee and the shipper in the tenderLoad by The Motor Carrier" />
            <NoteComponent note="Once a tender is Accepted a load will be created for in the DRAYOS for the Motor Carrier and the tender will have referenceNumber which is the Load Reference Number in DRAYOS." />
          </section>
        </div>
        </div>
      </div>
    </div>
  )
}

export default QuickStartTender