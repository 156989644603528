import { GET_WEBHOOK_FAIL, GET_WEBHOOK_REQUEST, GET_WEBHOOK_SUCCESS, REFRESH_TOKEN_FAIL, REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, TOKEN_CLEAR, UPDATE_WEBHOOK_RESET, WEBHOOK_FAIL, WEBHOOK_REQUEST, WEBHOOK_SUCCESS, WEBHOOK_TOKEN_CLEAR, WEBHOOK_TOKEN_FAIL, WEBHOOK_TOKEN_REQUEST, WEBHOOK_TOKEN_SUCCESS, GET_WEBHOOK_HISTORY_REQUEST, GET_WEBHOOK_HISTORY_SUCCESS, GET_WEBHOOK_HISTORY_FAIL } from "../constants/apiKeyConstants";
import { CLEAR_ERRORS } from "../constants/userConstants";

export const apiKeyInitialState = {
    refreshToken: "",
    webhookToken: "",
    isExpired: false,
    loading: false,
    isWebhook: false,
    eventFrom: [],
    webhookHistory:[],
    webhookUrls: "",
    getLoading: false,
    error: null,
}

export const apiKeyReducer = (state = { ...apiKeyInitialState }, action) => {
    switch (action.type) {
        case REFRESH_TOKEN_REQUEST:
        case WEBHOOK_TOKEN_REQUEST:
        case WEBHOOK_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_WEBHOOK_REQUEST:
        case GET_WEBHOOK_HISTORY_REQUEST: 
            return {
                ...state,
                getLoading: true,
            }
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                refreshToken: action?.payload,
                isExpired: action?.isExpired,
            }
        case WEBHOOK_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                webhookToken: action?.payload
            }
        case WEBHOOK_SUCCESS:
            return {
                ...state,
                loading: false,
                isWebhook: true,
                eventFrom: action?.eventType,
                webhookUrls: action?.webhookUrl
            }
        case GET_WEBHOOK_SUCCESS:
            return {
                ...state,
                getLoading: false,
                isWebhook: action?.payload,
                eventFrom: action?.eventType,
                webhookUrls: action?.webhookUrl
            }
        case UPDATE_WEBHOOK_RESET:
            return {
                ...state,
                isWebhook: false
            }
        case REFRESH_TOKEN_FAIL:
        case WEBHOOK_TOKEN_FAIL:
        case WEBHOOK_FAIL:
            return {
                ...state,
                loading: false,
                error: action?.error,
            }
        case GET_WEBHOOK_FAIL:
        case GET_WEBHOOK_HISTORY_FAIL:
            return {
                ...state,
                getLoading: false,
                error: action?.error,
            }
        case GET_WEBHOOK_HISTORY_SUCCESS:
            return {
                ...state,
                webhookHistory: action?.payload,
                getLoading:false
            }
        case TOKEN_CLEAR:
            return {
                ...state,
                refreshToken: "",
            }
        case WEBHOOK_TOKEN_CLEAR:
            return {
                ...state,
                webhookToken: "",
                eventFrom: [],
                webhookUrls: ""
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
};
