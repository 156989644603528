import React from 'react'
import DynamicComponent from "../../../../components/Common/DynamicComponent/DynamicComponent";

import retrieveContainerOwnersData from './../constants/container/containerOwners.json' 
const data = {
  title: "Retrieve All ContainerOwners",
  subTitle: "This endpoint retrieves all container owners. You can use this to update relevant information in your system.",
  requestLink: "GET  /tenders/container-owners",
  queryParameters: null,
  requestParameters: null,
  note: "When creating a tender or updating tender send the containerOwner name in the carrierIdentifierCode to assign containerOwner to a Tender",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
};

const content = JSON.stringify(retrieveContainerOwnersData,null,4)

const RetrieveContainerOwners = () => {
  return (
    <div>
      <DynamicComponent props={data} content={content}/>
    </div>
  )
}

export default RetrieveContainerOwners