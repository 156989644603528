import React from 'react'
import DynamicComponent from '../../../components/Common/DynamicComponent/DynamicComponent'

const data = {
  title: "Get All Carriers",
  subTitle: "This endpoint will retrieve all carriers or a particular, individual carrier.",
  requestLink: "GET  /carriers",
  title2: "API Setup",
  requestLink2: "Authorization: <Your access Token>",
  queryParameters: [
    {
      parameter: "searchTerm",
      type: "String",
      description: "Carrier search string"
    },
    {
      parameter: "skip",
      type: "Int[int32]",
      description: "Skip carrier count"
    },
    {
      parameter: "limit",
      type: "Int[int32]",
      description: "Limit on carrier count page"
    }
  ],
  note: "Here you receive a carrierID with Company name. We use carrierID for requesting a particular Company.",
};

const jsonContent = `{
  "_object": "/api/response",
  "self": "/v1/carriers/",
  "version": "1.0.0",
  "count": "111",
  "data": [
      {
          "company_name": "test 63 sons",
          "carrierID": "6FF0944777",
          "MCNumber": 123456,
          "USDOTNumber": "USDOT",
          "enabled" : "yes" // You've access to this carrier or not
      },
      {
          "company_name": "test 60 sons",
          "carrierID": "E502A46334",
          "MCNumber": 123456,
          "USDOTNumber": "USDOT",
          "enabled" : "no"
      }
  ],
  "error": null
}
`;
const GetAllCarriers = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent}/>
    </div>
  )
}

export default GetAllCarriers
