import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Caller",
  subTitle: "Caller object matrix",
  customName:" ",
  queryParameters: [
    {
      parameter: "callerId",
      type: "string",
      description: "Unique identifier for the Caller object",
    },
    {
      parameter: "callerName",
      type: "string",
      description: "Name of Caller",
    },
    {
      parameter: "email",
      type: "string",
      description: "Email of Caller",
    },
    {
      parameter: "isEmailNeedToSend",
      type: "boolean",
      description: "Is Email Need To Send or not",
    },
    {
      parameter: "address",
      type: "object",
      description: "Address object of Caller",
    },
    {
      parameter: "address1",
      type: "string",
      description: "Address of Caller",
    },
    {
      parameter: "city",
      type: "string",
      description: "City name of Caller",
    },
    {
      parameter: "state",
      type: "string",
      description: "State name of Caller",
    },
    {
      parameter: "zip",
      type: "string",
      description: "Zip code of Caller",
    },
    {
      parameter: "country",
      type: "string",
      description: "Country name of Caller",
    },
  ],
};

const jsonContent = `{
  "callerId": "dpsunday8425",
  "callerName": "dpsunday",
  "email": "jim5n0cj06ja4mo@portpro.io",
  "isEmailNeedToSend": true,
  "address": {
      "address1": "8201 Vineland Ave Suite 1801",
      "city": "Orlando",
      "state": "FL",
      "zip": "32821",
      "country": "US"
  }`;

const Caller = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};

export default Caller;
