import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  generateRefreshToken,
  getRefreshToken,
} from "../../actions/apiKeyActions";
import { clearErrors } from "../../actions/userActions";
import Loader from "../../components/Common/Loader/Loader";
import confirm from "../../components/Common/Modal/Modal";
import UnImg from "./server-under.jpg";
import Layout from "../../components/Common/Layout";

const star = "*";

const ApiKeys = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { token } = useSelector((state) => state.user);
  const { refreshToken, loading, isExpired, error } = useSelector(
    (state) => state.apiKey,
    shallowEqual
  );
  const [isHide, setIsHide] = useState(true);
  const [tab, setTab] = useState("sandbox");
  const handleCopyButton = (data) => {
    const el = document.createElement("textarea");
    el.value = data ? refreshToken : refreshToken;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  const handleGenerateToken = () => {
    refreshToken !== "" ? confirm("Are you sure you want to regenerate refresh token?", "If you regenerate refresh token your previous refresh token will be invalid.", (confirm) => {
      if (!confirm) return
      dispatch(generateRefreshToken(token, tab));
    }) : dispatch(generateRefreshToken(token, tab));
  };

  useEffect(() => {
    if (isExpired) {
      alert.error("Your Refresh Token is Expired. Please Regenerate it.")
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getRefreshToken(token, tab));
    return () => {
      dispatch({ type: "TOKEN_CLEAR" });
    };
  }, [tab, token, alert, isExpired]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  },[error])

  return (
    <>
    <Layout>
      {loading ? <Loader /> : null}
      <div className="p-5">
        <h3>API Keys</h3>
        <h6>There are two type of environment one is Production and Sandbox. Production refresh token is used for live data and Sandbox refresh token is used for dummy data. </h6>

        <div className="filter-row d-flex align-items-center my-2 pt-3">
          <ul className="nav nav-compact" role="tablist">
          <li className="nav-item">
              <span
                className={`nav-link ${tab === "sandbox"
                  ? "active"
                  : "inactive"
                  } pointer`}
                role="tab"
                onClick={() => { setTab("sandbox"); setIsHide(true) }}
              >
                Sandbox
              </span>
            </li>
            <li className="nav-item">
              <span
                className={`nav-link ${tab === "production"
                  ? "active"
                  : "inactive"
                  } pointer`}
                role="tab"
                onClick={() => { setTab("production"); setIsHide(true) }}
              >
                Production
              </span>
            </li>
          </ul>
        </div>
         <div className="card p-20 border-0">
          <h3 className="font-18 mb-20">Refresh Token</h3>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Generate refresh token"
              className="form-control"
              style={isExpired ? { backgroundColor: "white", color: "black", borderColor: "red" } : { backgroundColor: "white", color: "black" }}
              disabled={true}
              value={
                !refreshToken
                  ? ""
                  : isHide
                    ? star.repeat(200)
                    : refreshToken
              }
              id="refreshToken"
            />
            <button
              onClick={() => setIsHide(!isHide)}
              type="button"
              className="btn btn-info text-nowrap mx-2"
            >
              {isHide ? (
                <i className="uil uil-eye"></i>
              ) : (
                <i className="uil uil-eye-slash"></i>
              )}
            </button>

            <button
              className="btn btn-info text-nowrap"
              onClick={() => handleCopyButton("data")}
            >
              <i className="uil uil-copy"></i>
            </button>
          </div>
          {isExpired ? <div className="mt-2" style={{ color: "red" }}>Your refresh token is expired please regenerate.</div> : null}
          <div>
            <button className="btn btn-primary mt-3" onClick={handleGenerateToken} >
              {!refreshToken ? (
                <span>Generate</span>
              ) : (
                <span>Regenerate</span>
              )}
            </button>
          </div>
        </div>
        
        {tab !== "production" && <div className="bg-gray-600 px-20 py-15 mt-20">
          <span className="font-12 text-capitalize text-white">
            <i className="uil-info-circle font-16 mr-1"></i> You can use this refresh token to generate/Regenerate access and carrier token.
          </span>
        </div>}
      </div>
      </Layout>
    </>
  );
};

export default ApiKeys;
