import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, signInUser } from "../../actions/userActions";
import { useAlert } from "react-alert";
import {
  IconEye,
  IconEyeHidden,
  IconLogo,
} from "../../components/Common/Icons";
import { Link, useHistory } from "react-router-dom";

const SignIn = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { error, isAdminVerified, loading } = useSelector(
    (state) => state.user
  );
  const alert = useAlert();
  let history = useHistory();

  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = signInData;
  const handleSignInData = (e) => {
    setSignInData({ ...signInData, [e.target.name]: e.target.value });
  };
  const submitSignInData = (e) => {
    e.preventDefault();
    dispatch(signInUser(signInData));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isAdminVerified) {
      history.push("/");
    }
  }, [dispatch, error, alert, history, isAdminVerified]);

  return (
    <>
        <div className="signup-wrapper bg-animation h-100 bg-gray-50">
          <div className="d-flex align-items-center justify-content-end position-fixed right-0 mr-10 mt-10">
            <div className="text-muted mr-10">Don't have an account?</div>
            <button className="btn btn btn-outline-light bg-white" onClick={() => history.push({ pathname: "/signup", state: { fromButton: true } })}>
              Sign Up
            </button>
          </div>
          <div className="row no-gutters d-flex min-vh-100 align-items-center">
            <div className="col-lg-3 col-xl-3"></div>
            <div className="col-lg-6 col-xl-6">
              <div className="d-flex align-items-center justify-content-center flex-wrap">
                <div className="w-100 d-flex justify-content-center align-items-end">
                  <div className="">
                    <IconLogo />
                    <div className="bg-dark p-10 text-center text-white font-12 text-uppercase letter-spacing-5">
                      Developers
                    </div>
                  </div>
                </div>
                <h3 className="text-center mb-50 w-100 d-block col-auto mb-20 mt-40 font-bold font-24">
                  {/* Become a PortPro Developer! */}
                </h3>
                <div className="form-wrapper w-max p-50 bg-white rounded-10">
                  <form className="form-sm" onSubmit={submitSignInData}>
                    <div className="form-group mb-20">
                      <label className="mb-10">
                        <span className="text-danger">*</span> Email Address
                      </label>
                      <input
                        className="form-control p-10"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={handleSignInData}
                        required
                      />
                      <div className="text-gray-400 font-12 mt-1">
                        Your email address will be used as your login.
                      </div>
                    </div>
                    <div className="form-group mb-20">
                      <label className="mb-10">
                        <span className="text-danger">*</span> Password
                      </label>
                      <div className="input-wrapper">
                        <input
                          type={show ? 'text' : "password"}
                          className="form-control p-10"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={handleSignInData}
                          required
                        />
                        <span className="input-icon" onClick={() => setShow(!show)}>
                          {show ? <IconEyeHidden /> : <IconEye />}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Link to="forgot-password">Forgot password? </Link>
                    </div>
                    <div className="form-group mt-40">
                      <button
                        className="btn btn-lg btn-primary w-100 justify-content-center"
                        type="submit"
                        disabled={loading}
                      >
                        Sign In {loading && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-xl-3"></div>
          </div>
        </div>
    </>
  );
};
export default SignIn;
