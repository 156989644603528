import React, { useEffect, useRef, useState } from "react";
import { IconLogo } from "../Icons";
import { logOut } from "../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import UpdateProfile from "../../UpdateProfile/UpdateProfile";
import ResetPassword from "../../ResetPassword/ResetPassword";
import Avatar from "../Avatar/Avatar";


export const HeaderTopBar = () => {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.user);
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [profileOpen,setProfileOpen] = useState(false);
  const [resetPassOpen,setResetPassOpen] = useState(false);
  const handleLogOut = () => {
    dispatch(logOut());
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);
  return (
    <>
      <div className="topbar">
        <div className="d-flex align-items-center">
          <div className="" style={{ width: "225px" }}>
            <div className="text-center pt-10 pb-10">
              <IconLogo />
              <div className="bg-dark p-10 text-center text-white font-12 line-height-5 text-uppercase d-inline-block letter-spacing-5">
                Developers
              </div>
            </div>
          </div>
          <div className="header_content p-10">
            <div className="d-flex justify-content-between">
              <div className="app-search header-search position-relative "></div>
              <div className="d-flex justify-content-center align-items-center" onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
                <Avatar className="mr-1">{name.slice(0,1).toUpperCase()}</Avatar>
              <div
                className="mr-3"
                style={{ position: "relative" }}
                ref={ref}
              >
                
                <span className="ml-1 mt-1">
                  Hi,{" "}
                  <span className="font-medium text-dark">
                    {name.toUpperCase()}
                  </span>
                </span>
                {open && (
                  <div
                    id="popover-contained"
                    className="card card--shadow-5 border-0 popdrop popdrop--bottom-no-space popdrop--right mb-0 p-2 animated w-200 mt-2"
                  >
                    <li className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer" onClick={()=>{setProfileOpen(true);setOpen(false)}}>
                    <i className="uil uil-user"></i>
                      <span className="ml-2">Profile</span>
                    </li>
                    <li className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer" onClick={()=>{setResetPassOpen(true);setOpen(false)}}>
                    <i className="uil uil-key-skeleton-alt"></i>
                      <span className="ml-2">Reset Password</span>
                    </li>
                    <li
                      className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer"
                      onClick={() => handleLogOut()}
                    >
                      <i className="uil uil-sign-out-alt"></i>
                      <span className="ml-2">Logout</span>
                    </li>
                  </div>
                )}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateProfile setOpen={setProfileOpen} open={profileOpen}/>
      <ResetPassword setOpen={setResetPassOpen} open={resetPassOpen}/>      
    </>
  );
};
