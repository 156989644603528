import React from 'react'
import DynamicComponent from '../../../components/Common/DynamicComponent/DynamicComponent';
import { TENDER_CONSTANTS } from './constants/tender/tender.constants';
import updateTender from './constants/updateTender.json';

const data = {
  title: "Update Tender",
  subTitle: "This endpoint updates a Tender.",
  requestLink: "PUT   /tenders",
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>",
  requestParameters: [
    {
      parameter: 'tenderReferenceNumber*',
      type: 'string',
      description: 'Tender Reference Number of Tender to update',
      isRequired: true
    },
    {
      parameter: 'typeOfLoad',
      type: 'string',
      description: 'Type of the load. One of: [IMPORT, EXPORT, ROAD]',
    },
    {
      parameter: 'preSetOrderStatus',
      type: 'string',
      description: `Type of the order status. One of: ${TENDER_CONSTANTS.preSetOrderStatus}`,
    },
    {
      parameter: 'pricing',
      type: 'array[object]',
      description: 'Creates pricing for tender load and price is not required in payload',
    },
    {
      parameter: 'temperature',
      type: 'string',
      description: 'Temperature of load',
    },
    {
      parameter: 'deliveryOrderNo',
      type: 'string',
      description: 'Delivery order no of load',
    },
    {
      parameter: 'callerbillLandingNo',
      type: 'string',
      description: 'Master Bill of Lading of load',
    },
    {
      parameter: 'contact',
      type: 'Record<String,String>',
      description: 'Contact object containing name email mobile and lastName',
    },
    {
      parameter: 'shipperContact',
      type: 'Record<String,String>',
      description: 'Contact object containing name email mobile and lastName',
    },
    {
      parameter: 'consigneeContact',
      type: 'Record<String,String>',
      description: 'Contact object containing name email mobile and lastName',
    },
    {
      parameter: 'secondaryReferenceNo',
      type: 'string',
      description: 'Secondary reference number of load',
    },
    {
      parameter: 'doNo',
      type: 'string',
      description: 'House Bill of Lading of load',
    },
    {
      parameter: 'bookingNo',
      type: 'string',
      description: 'Booking no of load',
    },
    {
      parameter: 'sealNo',
      type: 'string',
      description: 'Seal number of load',
    },
    {
      parameter: 'purchaseOrderNo',
      type: 'string',
      description: 'Purchase order number of load',
    },
    {
      parameter: 'workOrderNo',
      type: 'string',
      description: 'Work order number of load',
    },
    {
      parameter: 'shipmentNo',
      type: 'string',
      description: 'Shipment number of load',
    },
    {
      parameter: 'carrierIdentifierCode',
      type: 'string',
      description: 'Company name of the container owner assigns containerOwner to the tender load',
    },
    {
      parameter: 'containerType',
      type: 'string',
      description: 'Type of the container',
    }, {
      parameter: 'containerSize',
      type: 'string',
      description: 'Size of the container',
    },
    {
      parameter: 'shipperName',
      type: 'string',
      description: 'Name of shipper '
    },
    {
      parameter: 'shipperStreetAddress',
      type: 'string',
      description: 'StreetAddress of shipper '
    },
    {
      parameter: 'shipperCity',
      type: 'string',
      description: 'City of shipper '
    },
    {
      parameter: 'shipperStateCode',
      type: 'string',
      description: 'StateCode of shipper '
    },
    {
      parameter: 'shipperCountryCode',
      type: 'string',
      description: 'CountryCode of shipper '
    },
    {
      parameter: 'shipperZip',
      type: 'number',
      description: 'Zip of shipper '
    },
    {
      parameter: 'consigneeName',
      type: 'string',
      description: 'Name of consignee '
    },
    {
      parameter: 'consigneeStreetAddress',
      type: 'string',
      description: 'StreetAddress of consignee '
    },
    {
      parameter: 'consigneeCity',
      type: 'string',
      description: 'City of consignee '
    },
    {
      parameter: 'consigneeStateCode',
      type: 'string',
      description: 'StateCode of consignee '
    },
    {
      parameter: 'consigneeCountryCode',
      type: 'string',
      description: 'CountryCode of consignee '
    },
    {
      parameter: 'consigneeZip',
      type: 'number',
      description: 'Zip of consignee '
    },
    {
      parameter: 'emptyOriginName',
      type: 'string',
      description: 'Name of emptyOrigin'
    },
    {
      parameter: 'emptyOriginStreetAddress',
      type: 'string',
      description: 'StreetAddress of emptyOrigin'
    },
    {
      parameter: 'emptyOriginCity',
      type: 'string',
      description: 'City of emptyOrigin'
    },
    {
      parameter: 'emptyOriginStateCode',
      type: 'string',
      description: 'StateCode of emptyOrigin'
    },
    {
      parameter: 'emptyOriginCountryCode',
      type: 'string',
      description: 'CountryCode of emptyOrigin'
    },
    {
      parameter: 'emptyOriginZip',
      type: 'number',
      description: 'Zip of emptyOrigin'
    },
    {
      parameter: 'mustRespondBy',
      type: 'date',
      description: 'MustRespondBy of load'
    },
    {
      parameter: 'scac',
      type: 'string',
      description: 'Receiver scac code'
    },
    {
      parameter: 'loadNote',
      type: 'string',
      description:'Load Notes of  tender'
    },
  ],
  note: '',
  // remember: "",
};

const paramsParameters = [
  {
    parameter: 'tenderReferenceNumber',
    isRequired: true,
    type: 'string',
    description: 'Tender Reference Number of Tender to update',
  },
]

const data2 = {
  ...data,
  requestLink: "PUT   /tenders/{{tenderReferenceNumber}}",
  paramsParameters,
}
const jsonContent = JSON.stringify(updateTender, null, 4)


const UpdateTender = () => {
  return (
    <>
      <DynamicComponent props={data2} content={jsonContent} />
      <hr />
      <DynamicComponent props={data} content={jsonContent} />
    </>
  )
}

export default UpdateTender