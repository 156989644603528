import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Sandbox",
  requestLink: "GET https://sandbox.myapps.portpro.io/v1/webhook/trigger/sandbox",
  description: "We're providing sandbox environment for setup our webhook into your system. so for that we're providing one REST API and you just want to trigger that api with your any valid carrier sandbox url and it will trigger webhook for that event in your sandbox webhook url. you can use this REST API any time for testing purpose and it will be easy for you to setup webhook in your system.",
  typeTitle: "Available Event types",
  types:[
    "appointments#port_pickup",
    "appointments#consignee",
    "appointments#port_return",
    "load#chassis_pick",
    "load#chassis_termination",
    "load#pull_container",
    "load#deliver_load",
    "load#return_container",
    "load#invoice_created",
    "tendor#status_changed",
    "load#status_updated",
    "load#hook_container",
    "load#drop_container",
    "load#notes"
  ],
  queryParameters: [
  {
    parameter: "eventType",
    type: "String[Only accept available EventTypes]",
    description: "Type of event which you want to trigger.",
    isRequired: true
  },
  {
    parameter: "webhookUrl",
    type: "String",
    description: "REST API url in which you want to trigger webhook event.",
    isRequired: true
  }
  ],
  title2: "API Setup",
  requestLink2: "Authorization: Bearer <Your Carrier Token>[Sandbox]",
}

const jsonContent = `{
    "Demo webhook data Objects"

    "tendor#status_changed": {
      "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
      "eventType": "tendor#status_changed",
      "tenderReferenceNumber": "TENDER_REFERENCE_NUMBER",
      "status": "Accepted",
      "carrierID": "54F68BB946"
    },
    "load#status_updated": {
      loadReferenceNumber: 'ABCD_123',
      eventType: 'load#status_updated',
      status: 'PULLCONTAINER_DEPARTED',
      carrierID: 'ABC1234'
    },
    "load#notes": {
      "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
      "eventType": "load#notes",
      "carrierID": "54F68BB946",
      "loadNotes": {
          "officeNote": "Office Notes",
          "billingNote": "Billing Notes"
        }
    },
    "load#return_container": {
            "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
            "eventType": "load#return_container",
            "returnContainer": {
              "returncontainerCompanyName": "Company Name",
              "departedTime": "2022-07-21T05:05:35.466Z",
              "arrivedTime": "2022-07-21T05:05:35.466Z",
              "type": "RETURNCONTAINER",
              "address": "4616 Crossroads Park Dr, Liverpool, NY 13088, USA",
              "isVoidOut": false,
              "distance": 0
            },
            "carrierID": "54F68BB946"
    },
    "load#deliver_load": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "load#return_container",
        "deliverLoad": {
            "deliverloadCompanyName": "Company Name",
            "departedTime": "2022-07-21T05:05:35.466Z",
            "arrivedTime": "2022-07-21T05:05:35.466Z",
            "type": "DELIVERLOAD",
            "address": "4616 Crossroads Park Dr, Liverpool, NY 13088, USA",
            "isVoidOut": false,
            "distance": 0
          },
        "carrierID": "54F68BB946"
    },
    "load#drop_container": {
      "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
      "eventType": "load#drop_container",
      "carrierID": "54F68BB946",
      "dropContainer": {
          "dropcontainerCompanyName": "Company Name",
          "departedTime": "2022-07-21T05:05:35.466Z",
          "arrivedTime": "2022-07-21T05:05:35.466Z",
          "type": "DROPCONTAINER",
          "address": "4616 Crossroads Park Dr, Liverpool, NY 13088, USA",
          "isVoidOut": false,
          "distance": 0
        }
    },
    "load#hook_container": {
      "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
      "eventType": "load#drop_container",
      "carrierID": "54F68BB946",
      "hookContainer": {
          "hookcontainerCompanyName": "Company Name",
          "departedTime": "2022-07-21T05:05:35.466Z",
          "arrivedTime": "2022-07-21T05:05:35.466Z",
          "type": "HOOKCONTAINER",
          "address": "4616 Crossroads Park Dr, Liverpool, NY 13088, USA",
          "isVoidOut": false,
          "distance": 0
        }
    },
    "load#pull_container": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "load#pull_container",
        "carrierID": "54F68BB946",
        "pullContainer": {
            "pullcontainerCompanyName": "Company Name",
            "departedTime": "2022-07-21T05:05:35.466Z",
            "arrivedTime": "2022-07-21T05:05:35.466Z",
            "type": "PULLCONTAINER",
            "address": "4616 Crossroads Park Dr, Liverpool, NY 13088, USA",
            "isVoidOut": false,
            "distance": 0
          }
    },
    "appointments#port_pickup": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "appointments#port_pickup",
        "carrierID": "54F68BB946",
        "pickupTimes": [
            {
              "port": "PNCT",
              "pickupFromTime": "2022-07-02T04:00:00.000Z",
              "pickupToTime": "2022-07-02T04:00:00.000Z"
            }
          ]
    },
    "appointments#consignee": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "appointments#consignee",
        "carrierID": "54F68BB946",
        "deliveryTimes": [
            {
              "deliveryFromTime": "2022-07-21T04:00:00.000Z",
              "deliveryToTime": "2022-07-21T04:00:00.000Z",
              "consignee": "CONSIGNEE NAME"
            }
          ]
    },
    "appointments#port_return": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "appointments#port_return",
        "carrierID": "54F68BB946",
        "portReturn": {
            "returnFromTime": "2022-07-09T04:00:00.000Z",
            "returnToTime": "2022-07-09T04:00:00.000Z",
            "port": "PORT NAME"
          }
    },
    "load#chassis_pick": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "load#chassis_pick",
        "carrierID": "54F68BB946",
        "chassisPickup": {
            "chassispickCompanyName": "Company Name",
            "departedTime": "2022-07-26T06:04:41.106Z",
            "arrivedTime": "2022-07-26T06:04:41.106Z",
            "type": "CHASSISPICK",
            "address": "1210 Corbin St, Elizabeth, NJ 07201, USA",
            "isVoidOut": false,
            "distance": 0
        }
    },
    "load#chassis_pick": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "load#chassis_pick",
        "carrierID": "54F68BB946",
        "chassisPickup": {
            "chassispickCompanyName": "MAHER",
            "departedTime": "2022-07-26T06:04:41.106Z",
            "arrivedTime": "2022-07-26T06:04:41.106Z",
            "type": "CHASSISPICK",
            "address": "1210 Corbin St, Elizabeth, NJ 07201, USA",
            "isVoidOut": false,
            "distance": 0
        }
    },
    "load#chassis_termination": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "load#chassis_termination",
        "carrierID": "54F68BB946",
        "chassisTermination": {
            "chassisterminationCompanyName": "GCT BAYONNE",
            "departedTime": "2022-07-26T06:04:41.106Z",
            "arrivedTime": "2022-07-26T06:04:41.106Z",
            "type": "CHASSISTERMINATION",
            "address": "302 Port Jersey Blvd, Jersey City, NJ 07305, USA",
            "isVoidOut": false,
            "distance": 0
          }
    },
    "load#invoice_created": {
        "loadReferenceNumber": "LOAD_REFERENCE_NUMBER",
        "eventType": "load#invoice_created",
        "carrierID": "54F68BB946",
        "invoice": {
            "load_reference_number": "LOAD_REFERENCE_NUMBER",
            "eventType": "load#invoice_created",
            "invoiceDetails": {
              "_id": "LOAD_REFERENCE_NUMBER",
              "total": 1,
              "invoice": [
                {
                  "load_reference_number": "LOAD_REFERENCE_NUMBER",
                  "reference_number": "LOAD_REFERENCE_NUMBER",
                  "pricing": [
                    {
                      "chargeType": "CUSTOMER_RATES",
                      "name": "Base Price",
                      "perType": "",
                      "freeDays": 0,
                      "unit": "0.00",
                      "finalAmount": "1935.00",
                      "chargePerMinute": 0,
                      "chargePerDay": 0,
                      "amount": "0.00"
                    },
                    {
                      "chargeType": "CUSTOMER_RATES",
                      "name": "Chassis",
                      "perType": "",
                      "freeDays": 0,
                      "unit": "0.00",
                      "finalAmount": "280.00",
                      "chargePerMinute": 0,
                      "chargePerDay": 0,
                      "amount": "0.00"
                    }
                  ],
                  "status": "BILLING",
                  "paidAmount": 0,
                  "totalAmount": 2215,
                  "remainAmount": 2215,
                  "billingDate": "2022-07-26T06:04:11.295Z",
                  "caller": {
                    "company_name": "Company Name"
                  },
                  "callerName": "Company Name",
                  "referenceFields": {
                    "containerNo": "Container No",
                    "purchaseOrderNo": "PurchaseOrder",
                    "masterBillOfLading": "Master Bill of Lading",
                    "pickUpNo": "Pick Up #",
                    "vesselName": "Vessel Name",
                    "houseBillOfLading": "House Bill of Lading",
                    "voyage": "Voyage",
                    "returnNo": "Return #",
                    "secondaryReferenceNo": "Reference #",
                    "sealNo": "Seal #",
                    "shipmentNo": "Shipment #"
                  }
                }
              ]
            }
        }
    }
}`;

export const Sandbox = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};
