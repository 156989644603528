import axios from "axios"
import {
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    CLEAR_ERRORS,
    OTP_REQUEST,
    OTP_SUCCESS,
    OTP_FAIL,
    RESEND_OTP_REQUEST,
    RESEND_OTP_SUCCESS,
    RESEND_OTP_FAIL,
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    SIGNIN_FAIL,
    UPDATE_PROFILE,
    UPDATE_PROFILE_FAIL,
} from "../constants/userConstants";

export const signupUser = (signUpData) => async (dispatch) => {
    try {
        dispatch({ type: SIGNUP_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }
        const query = {
            url: `${process.env.REACT_APP_AUTH_URL}/signup`,
            method: "post",
            headers: config.headers,
            data: signUpData
        }
        const { data } = await axios(query);
        dispatch({ type: SIGNUP_SUCCESS, payload: data?.access, email: data?.data?.email })
    } catch ({ ...err }) {
        dispatch({ type: SIGNUP_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
    }
}
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};

export const verifyOtp = (otp) => async (dispatch) => {
    try {
        dispatch({ type: OTP_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }
        const query = {
            url: `${process.env.REACT_APP_AUTH_URL}/verify-otp`,
            method: "post",
            headers: config.headers,
            data: otp
        }
        const { data } = await axios(query);
        dispatch({ type: OTP_SUCCESS, payload: data?.access, email: data?.email })
    } catch ({ ...err }) {
        dispatch({ type: OTP_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
    }
}
    
export const resendOtp = (email,isForgot) => async (dispatch) => {
    try {
        dispatch({ type: RESEND_OTP_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }
        let url = isForgot ? `${process.env.REACT_APP_AUTH_URL}/resend-otp?email=${email}&isForgot=${true}` : `${process.env.REACT_APP_AUTH_URL}/resend-otp?email=${email}`;
        const query = {
            url: url,
            method: "get",
            headers: config.headers,
        }
        const { data } = await axios(query);
        dispatch({ type: RESEND_OTP_SUCCESS, payload: data?.data?.message })
    } catch ({ ...err }) {
        dispatch({ type: RESEND_OTP_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
    }
}

export const logOut = () => async (dispatch) => {
    try {
        dispatch({ type: "USER_LOGOUT" });
    } catch (e) {
    }
}


export const signInUser = (signInData) => async (dispatch) => {
    try {
        dispatch({ type: SIGNIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }
        const query = {
            url: `${process.env.REACT_APP_AUTH_URL}/login`,
            method: "post",
            headers: config.headers,
            data: signInData
        }
        const { data } = await axios(query);
        dispatch({ type: SIGNIN_SUCCESS, token: data?.data?.token, email: data?.data?.email, name: data?.data?.name })
    } catch ({ ...err }) {
        dispatch({ type: SIGNIN_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
    }
}

export const getProfile = (token) => async (dispatch) => {
    try{
        const query = {
            url: `${process.env.REACT_APP_AUTH_URL}/get-profile`,
            headers: {
              "Authorization": `${token}`
            },
            method: "get"
          }
          const { data } = await axios(query);
          dispatch({ type: UPDATE_PROFILE, payload: data?.data?.name })

    } catch ({ ...err }) {
        if(err.response.status === 401) {
        dispatch({ type: "USER_LOGOUT", error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
        } else {
        dispatch({ type: UPDATE_PROFILE_FAIL, error: err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong" })
        }
    }
}