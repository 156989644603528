import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IconEye, IconEyeHidden } from "../Common/Icons";

const ResetPassword = ({ setOpen, open }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldShow, setOldShow] = useState(false);
  const [newShow, setNewShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { token } = useSelector((state) => state.user);
  const alert = useAlert();
  const data = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };

  const UpdatePassword = (e) => {
    setLoading(true);
    const query = {
      url: `${process.env.REACT_APP_AUTH_URL}/reset-password`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      method: "post",
      data: data,
    };
    axios(query)
      .then(() => {
        setLoading(false);
        setIsError(false)
        alert.success("Password reset Successfully");
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsError(true);
      });
  };
  useEffect(() => {
    return () => {
      setIsError(false);
    };
  }, []);
  return (
    <Modal show={open} centered>
      <Modal.Header>
        <h4 classNa me="modal-title" id="myModalLabel">
          Reset Password
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group form-row align-items-center mb-20">
          <div className="col-md-3">
            <h5 className="mt-2">Old Password </h5>
          </div>
          <div className="col-md-9">
            <div className="input-wrapper">
              <input
                type={oldShow ? "text" : "password"}
                className="form-control"
                onChange={(e) => {
                  if (!e.target.value) setIsError(false);
                  setOldPassword(e.target.value);
                }}
                style={isError ? { borderColor: "red" } : {}}
              />
              {isError === true && (
                <span style={{ color: "red", position: "absolute" }}>
                  Please enter valid password
                </span>
              )}
              <span
                className="input-icon"
                onClick={() => setOldShow(!oldShow)}
              >
                {oldShow ? <IconEyeHidden /> : <IconEye />}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group form-row align-items-center">
          <div className="col-md-3">
            <h5 className="mt-2">New Password </h5>
          </div>
          <div className="col-md-9">
            <div className="input-wrapper">
              <input
                type={newShow ? "text" : "password"}
                className="form-control"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span className="input-icon" onClick={() => setNewShow(!newShow)}>
                {newShow ? <IconEyeHidden /> : <IconEye />}
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn text-gray-500"
          onClick={() => {
            setOpen(false);
            setIsError(false);
          }}
        >
          Cancel
        </button>
        <Button
          className="btn btn-lg btn-primary justify-content-center"
          type="submit"
          disabled={loading || (!oldPassword || !newPassword)}
          onClick={UpdatePassword}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>Update</>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetPassword;
