import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
    title: "Expense",
    subTitle: "Expense object matrix",
    customName:" ",
    queryParameters: [
      {
        parameter: "name",
        type: "string",
        description: "Name of item",
      },
      {
        parameter: "perType",
        type: "string",
        description: "Per Type of item",
      },
      {
        parameter: "amount",
        type: "int32",
        description: "Amount of item ",
      },
      {
        parameter: "unit",
        type: "int32",
        description: "Units of item ",
      },
      {
        parameter: "finalAmount",
        type: "int32",
        description: "Final amount amount of item ",
      },
    ],
  };
const jsonContent = `  {
    "name": "Base Price",
    "perType": "fixed",
    "amount": "0.00",
    "unit": 1,
    "finalAmount": "4.00"
},`;

export const Expense = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};
