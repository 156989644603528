import React from "react";
import DynamicComponent from "../../../components/Common/DynamicComponent/DynamicComponent";

const data = {
  title: "Document",
  subTitle: "Document object matrix",
  customName: " ",
  queryParameters: [
    {
      parameter: "date",
      type: "date/string",
      description: "Document upload date",
    },
    {
      parameter: "url",
      type: "string",
      description: "URL of document",
    },
    {
      parameter: "type",
      type: "string",
      description: "Type of Document",
    },
    {
      parameter: "checked",
      type: "boolean",
      description: "Document is checked or not",
    },
  ],
};
const jsonContent = `     {
    "date": "2022-07-28T09:45:05.069Z",
    "url": "https://s3.amazonaws.com/yru0938685.pdf",
    "type": "Bill of Lading",
    "checked": true
}`;

export const Document = () => {
  return (
    <div>
      <DynamicComponent props={data} content={jsonContent} />
    </div>
  );
};
